import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../../api/questionsApi';
import QuestionForAnswerCard from '../../../components/consultantsHome/QuestionForAnswerCard';
import LCButton from '../../../components/form/LCButton';
import { PAGINATION_LIMIT } from '../../../models/pagination';
import { ListQuestionsForAnswerRequest, ListQuestionsForAnswerResponseItem } from '../../../models/questionsApi';

const QuestionsForAnswerList = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<ListQuestionsForAnswerResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListQuestionsForAnswerRequest>({
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    loadQuestions();
  }, [request]);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.listQuestionsForAnswer(request).then(
      (res) => {
        setQuestions(questions.concat(res.data.data));
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const resetQuestions = () => {
    setQuestions([]);
    setTotal(0);
    setRequest({ offset: 0, limit: PAGINATION_LIMIT });
  };

  const handleOnLoadMore = () => {
    setRequest({ ...request, offset: request.offset + request.limit });
  };

  const shouldShowLoadMoreButton = () => questions.length < total;
  // console.log(questions);

  return (
    <>
      <p className="cl-p">يمكنك تصفح أحدث الاستشارات المسندة إليك للإجابة عليها من خلال هذه القائمة</p>

      {loading && !shouldShowLoadMoreButton() && <ProgressSpinner />}

      {questions.map((question) => (
        <QuestionForAnswerCard
          key={question.question?.id}
          id={question.question?.id}
          title={question.question?.title}
          date={question.question?.createdAt}
          content={question.question?.content}
          isJudge={false}
          status={question.question?.status}
          link={`/question-for-answer/${question.question?.id.toString()}`}
          questionRejectedCallback={resetQuestions}
          questionReassignedCallback={resetQuestions}
        />
      ))}

      {shouldShowLoadMoreButton() && (
        <div className="cl-btn">
          <LCButton
            type="button"
            label="عرض المزيد"
            buttonStyle="roundedStyle1"
            className="self-center mt-5 md:px-20"
            onClick={handleOnLoadMore}
            loading={loading}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};

export default QuestionsForAnswerList;
