import React from 'react';
import { Link } from 'react-router-dom';
import ClassNameInjector from '../ClassNameInjector';
import './LCButton.css';

interface LCLinkButtonProps {
  label: string;
  to: string;
  icon?: JSX.Element;
  buttonStyle?: 'primary' | 'primaryWhiteOutlined' | 'rounded';
  className?: string;
}

const LCLinkButton = ({ label, to, icon, className, buttonStyle = 'primary', ...props }: LCLinkButtonProps) => {
  const buttonStyleClassName = {
    primary: 'bg-primary text-white rounded-tl-xl rounded-br-xl px-20 py-1 font-main text-lg',
    primaryWhiteOutlined:
      'bg-transparent border border-white text-white rounded-none rounded-tl-xl rounded-br-xl px-20 py-1 font-main text-lg',
    rounded: 'bg-primary text-white rounded-xl px-10 font-main text-lg',
  };

  return (
    <Link {...props} to={to} className={`${buttonStyleClassName[buttonStyle]} ${className}`}>
      {icon && <ClassNameInjector className="inline ml-2">{icon}</ClassNameInjector>}
      {label}
    </Link>
  );
};

export default LCLinkButton;
