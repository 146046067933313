import { NavLink } from 'react-router-dom';
import './dashboard.css';
import { SidebarDataInterface } from './SideBarData';

interface SubMenuProps {
  item: SidebarDataInterface;
  toggleSide: () => void;
}

const SubMenu = ({ item, toggleSide }: SubMenuProps) => {
  return (
    <NavLink
      to={item.path}
      className={({ isActive }) =>
        `subMenuLink ${isActive ? ' activeSubMenuLink rounded-tr-md rounded-br-md su-link ' : 'su-link'}`
      }
      target={item?.target}
      onClick={toggleSide}
      end={item.linkEnd}>
      <div className="flex flex-row items-center ">
        <img src={item.imgSrc} alt={item.title} width="30" height="30" style={{ filter: 'brightness(0) invert(1)' }} />
        <h2 className="su-title">{item.title}</h2>
      </div>
    </NavLink>
  );
};

export default SubMenu;
