import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { UsersApi } from '../../api/usersApi';
import icon_users from '../../assets/icons/icon_users.svg';
import LCButton from '../../components/form/LCButton';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCSearch from '../../components/form/LCSearch';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { PAGINATION_LIMIT, paginatorTemplate } from '../../models/pagination';
import { ListUsersRequest, ListUsersResponseItem } from '../../models/usersApi';
import { getFullName } from '../../utility/stringUtility';
import { saveAs } from 'file-saver';
import { convertNumsToArabic } from '../../helpers/number_converter';
const UsersList = () => {
  const layoutContext = useContext(AdminAndSupportLayoutContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ListUsersResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListUsersRequest>({
    searchParam: '',
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    updateLayout();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [request]);

  const loadUsers = () => {
    setLoading(true);
    UsersApi.listUsers(request).then(
      (res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const updateLayout = () => {
    layoutContext.setAll({ title: 'إدارة المستخدمين', titleIcon: <img src={icon_users} alt="Icon" /> });
  };

  const mapIsEnabled = (isEnabled: boolean) => {
    if (isEnabled) return 'مفعل';
    else return 'معطل';
  };
  const mapIsDeleted = (isDeleted: boolean) => {
    if (isDeleted) return 'محذوف';
    else return 'غير محذوف';
  };

  const handleOnPage = (event: DataTablePFSEvent) => {
    setRequest({ ...request, offset: event.first, limit: event.rows });
  };
  const DownloadExcelSheet = async () => {
    await UsersApi.downloadUsersReport().then(
      (res) => {
        saveAs(res.data, 'المستخدمين.xlsx');
        //setSubmitting(false);
      },
      () => {
        //setSubmitting(false);
      }
    );
  };
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-center items-center lg:justify-between flex-col lg:flex-row gap-4">
        <LCSearch loading={loading} onSubmit={(searchParam) => setRequest({ ...request, searchParam, offset: 0 })} />
        <LCButton
          className="max-w-[140px]"
          label="تحميل"
          icon={<FaFileExcel className="ml-2 " />}
          buttonStyle="roundedStyle2"
          onClick={() => DownloadExcelSheet()}
        />
      </div>
      <DataTable
        value={users}
        loading={loading}
        scrollable
        scrollDirection="horizontal"
        className="lc-table"
        lazy
        paginator
        paginatorTemplate={paginatorTemplate}
        first={request.offset}
        rows={PAGINATION_LIMIT}
        tableStyle={{ width: '100%' }}
        totalRecords={total}
        onPage={handleOnPage}>
        <Column header="المعرف" body={(rowData) => convertNumsToArabic(rowData.id)} />
        <Column
          header="الاسم"
          body={(rowData) => getFullName(rowData)}
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        />
        <Column
          header="البريد الإلكتروني"
          field="email"
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        />
        <Column
          header="الحالة"
          body={(rowData) => (
            <div className={`${rowData.isEnabled ? `bg-certified` : `bg-rejected`}  rounded-md p-2 text-white`}>
              {mapIsEnabled(rowData.isEnabled)}
            </div>
          )}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="حالة الحساب"
          body={(rowData) => (
            <div className={`${!rowData.isDeleted ? `bg-certified` : `bg-rejected`}  rounded-md p-2 text-white`}>
              {mapIsDeleted(rowData.isDeleted)}
            </div>
          )}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="التفاصيل"
          body={(rowData) => <LCLinkButton label="عرض" to={rowData.id.toString()} buttonStyle="rounded" />}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
      </DataTable>
    </div>
  );
};

export default UsersList;
