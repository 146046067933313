import React from 'react';

interface StyleInjectorProps {
  children: JSX.Element | JSX.Element[];
  className: string;
}

const ClassNameInjector = ({ children, className }: StyleInjectorProps) => {
  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          className: `${child.props.className} ${className}`,
        })
      )}
    </>
  );
};

export default ClassNameInjector;
