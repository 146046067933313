import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { MdShare } from 'react-icons/md';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { StringLiteral } from 'typescript';
import { QuestionsApi } from '../../api/questionsApi';
import icon_chat from '../../assets/icons/icon_chat.svg';
import ConsultantContentCard from '../../components/ConsultantContentCard';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCRadioButton from '../../components/form/LCRadioButton';
import ShareQuestionWrapper from '../../components/ShareQuestionWrapper';
import TagsContentCard from '../../components/tagsContentCard';
import UserContentCard from '../../components/UserContentCard';
import { convertNumsToArabic } from '../../helpers/number_converter';
import { GetBankQuestionResponse } from '../../models/questionsApi';
import { IsEmpty, Loading } from '../../utility';
import { appendQuestionPrefix } from '../../utility/stringUtility';
import NextButtons from './components/NextButtons';

const QuestionBankDetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState<GetBankQuestionResponse>(null);
  const [user, setUser] = useState<{ firstName: string; lastName: string }>({
    firstName: '',
    lastName: '',
  });
  useEffect(() => {
    getQuestionsBanckDetails();
  }, []);

  useEffect(() => {
    getQuestionsBanckDetails();
  }, [id]);
  const getQuestionsBanckDetails = () => {
    setLoading(true);
    QuestionsApi.getBankQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        setQuestion(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const nextButtonPressed = () => {};

  const consnultantNameHandler = (question: GetBankQuestionResponse) => {
    // if (question.answers.length > 0) {
    if (question?.answers[0]?.editeAnswer)
      return {
        firstName: question?.reviews[0]?.user.firstName,
        lastName: question?.reviews[0]?.user.lastName,
      };
    else if (question?.answers[0]?.user?.isTeam)
      return {
        firstName: question?.reviews[0]?.user.firstName,
        lastName: question?.reviews[0]?.user.lastName,
      };
    else if (question?.reviews[question?.reviews.length - 1]?.changedByJudge)
      return {
        firstName: question?.reviews[0]?.user.firstName,
        lastName: question?.reviews[0]?.user.lastName,
      };
    else {
      return {
        firstName: question?.answers[0]?.user.firstName,
        lastName: question?.answers[0]?.user?.lastName,
      };
    }
  };
  console.log({ question });

  return (
    <div className="grow px-4 lg:px-16 py-8">
      <div className="hidden sm:flex gap-3 items-center mb-5">
        <p className="text-adminBreadcrumbs font-bold text-2xl">عرض الاستشارة</p>
        <div className="bg-adminBreadcrumbs w-1 self-stretch"></div>

        <div className="flex gap-1 items-center">
          <div className="flex items-center">
            <img src={icon_chat} alt="Icon" className="inline ml-2 h-6" />
            <Link to=".." className="text-xl">
              خزانة الاستشارات
            </Link>
          </div>

          <HiOutlineChevronRight className="text-lg" />

          <p className="text-xl">عرض الاستشارة</p>
        </div>
      </div>
      <Loading status={loading}>
        <IsEmpty empty={!question} message="لم يتم العثور على الاستشارة">
          <div className="bg-[#f9f9f9] px-3 lg:px-5 py-6 space-y-5">
            <div className="flex items-center justify-between">
              <div className="flex flex-col items-start w-1/2">
                <h1 className="text-primary text-lg md:text-3xl font-bold border-b-4 border-primary">
                  {question?.title}
                </h1>
                <p className="text-grey-500 text-lg md:text-md font-bold">رقم الاستشارة : {convertNumsToArabic(id)}</p>
              </div>
              <ShareQuestionWrapper questionId={id}>
                <button className="flex items-center gap-2 lg:border border-primary lg:rounded-lg lg:px-7 py-0.5">
                  <MdShare className="text-primary" />
                  <span className="hidden lg:inline">مشاركة الاستشارة</span>
                </button>
              </ShareQuestionWrapper>
            </div>
            <div className="order-1 lg:order-2">
              <p className="text-xl">تصنيف الاستشارة:</p>
              <div className="lg:flex gap-3 hidden">
                <LCRadioButton
                  formik={false}
                  disabled
                  checked
                  label={question?.category?.name}
                  value={`${question?.category.id}`}
                />
              </div>
              <div className="lg:flex gap-3 hidden">
                {question?.subCategory?.map((v, i) => (
                  <LCCheckbox key={i} value={`${v?.id}`} formik={false} label={v?.name} checked disabled />
                ))}
              </div>
              <div className="block lg:hidden mt-3">
                <LCRadioButton
                  formik={false}
                  disabled
                  checked
                  label={question?.category.name}
                  value={`${question?.category.id}`}
                />
              </div>
              <div className="block lg:hidden mt-3">
                {question?.subCategory?.map((v, i) => (
                  <LCCheckbox
                    key={i}
                    value={`${v?.id}`}
                    name="type"
                    id="type"
                    formik={false}
                    label={v?.name}
                    checked
                    disabled
                  />
                ))}
              </div>
            </div>
            <UserContentCard
              date={question?.createdAt}
              content={appendQuestionPrefix(question?.content)}
              backgroundColorClass="bg-[#eeeeee]"
            />

            {question && (
              <ConsultantContentCard
                consultantName={consnultantNameHandler(question)}
                contentDate={question?.answeredAt}
                type="answer"
                status={question?.answers[0].status}
                content={
                  question?.answers[0]?.adminContent ||
                  question?.reviews[0]?.judgeAnswer ||
                  question?.answers[0]?.content
                }
                rejectionReason={null}
              />
            )}
            <p className="text-gray-500 text-xs md:text-sm md:mr-12">الإجابة تمثل رأي المستشار</p>
            {question?.tags.length > 0 && <TagsContentCard tags={question?.tags} />}
          </div>
          <div style={{ margin: '10px auto' }} className="">
            <NextButtons
              nextQuestionId={question?.nextQuestionId}
              previousQuestionId={question?.previousQuestionId}
              onClickItem={nextButtonPressed}
            />
          </div>
        </IsEmpty>
      </Loading>
    </div>
  );
};

export default QuestionBankDetails;
