import { AxiosResponse } from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { BackendResponse } from '../../models/backendResponse';
import './dashboard.css';

interface BoxCollectionProps {
  icon: string;
  title: string;
  endpoint: () => Promise<AxiosResponse<BackendResponse<any>>>;
  responseFieldName: string;
}

const BoxCollection = ({ icon, title, endpoint, responseFieldName }: BoxCollectionProps) => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    endpoint().then(
      (res) => {
        setCount(res.data.data[responseFieldName]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="b-collection">
      <div className="b-count">
        <div className="">
          <img src={icon} alt={title} className="h-14" />
        </div>
        <div>
          {loading ? <ProgressSpinner className="h-10" strokeWidth="6" /> : <h4 className="b-count-number">{count}</h4>}
        </div>
      </div>
      <div>
        <h2 className="b-title">{title}</h2>
      </div>
    </div>
  );
};

export default BoxCollection;
