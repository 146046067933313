import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { BookApi } from '../api/bookApi';
import icon_book from '../assets/icons/icon_book.svg';
import LibraryCard from '../components/LibraryCard';
import { ListBookResponseItem } from '../models/bookApi';

const Library = () => {
  const [loading, setLoading] = useState(true);
  const [books, setBooks] = useState<ListBookResponseItem[]>([]);

  useEffect(() => {
    /*BookApi.listBooks().then(
      (res) => {
        setBooks(res.data.data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );*/
    window.location.href = "https://library.ksaa.gov.sa/"
  }, []);

  return (
    <div className="grow p-4 lg:p-10">
      <div className="flex items-center gap-3 mb-10">
        <img src={icon_book} alt="Icon" className="h-10" />
        <h1 className="text-xl lg:text-3xl font-bold font-header">جار نقلك إلى المكتبة الرقمية</h1>
      </div>
      <ProgressSpinner className="block mx-auto" />
      <p className="text-xl lg:text-3xl font-bold font-bold mb-5">إذا لم يتم نقلك تلقائياً <a href={"https://library.ksaa.gov.sa/"}>إضغط هنا</a></p>
{/*
      {loading ? (
        <ProgressSpinner className="block mx-auto" />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-x-2 gap-y-5">
          {books.map((book) => (
            <LibraryCard book={book} key={book.id} />
          ))}
        </div>
      )}
*/}
    </div>
  );
};

export default Library;
