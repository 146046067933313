import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

const DateSpan = ({ date, time }: { date: string; time: boolean }) => {
  const format = time ? 'YYYY-MM-DD h:mm:ss A' : 'YYYY-MM-DD';
  return date ? <span style={styles}>{dayjs(date).format(format)}</span> : <span>-</span>;
};

DateSpan.propTypes = {
  date: PropTypes.string,
  time: PropTypes.bool,
};

DateSpan.defaultProps = {
  time: false,
};

const styles: React.CSSProperties = {
  display: 'inline-block',
  direction: 'ltr',
};

export default DateSpan;
