import { useContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Navigate, Outlet } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import blackLogo from '../assets/images/academy_colored_logo.svg';
import ClassNameInjector from '../components/ClassNameInjector';
import '../components/Dashboard/dashboard.css';
import { SidebarData } from '../components/Dashboard/SideBarData';
import SubMenu from '../components/Dashboard/SubMenu';
import ErrorFallback from '../components/errorBoundary/ErrorBoundary';
import UserAccountButton from '../components/UserAccountButton';
import {
  AdminAndSupportLayoutContext,
  AdminAndSupportLayoutContextBreadcrumbs,
  AdminAndSupportLayoutContextUpdateParams,
} from '../contexts/AdminAndSupportLayoutContext';
import { UserContext } from '../contexts/UserContext';
import { UserTypes } from '../models/user';

const AdminAndSupportLayout = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();

  const userContext = useContext(UserContext);
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [titleIcon, setTitleIcon] = useState(null);
  const [titleAction, setTitleAction] = useState(null);
  const [breadcrumbsTitle, setBreadcrumbsTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState<AdminAndSupportLayoutContextBreadcrumbs[]>(null);

  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const [mediaToggle, setMediaToggle] = useState(false);
  const toggleMedia = () => setMediaToggle(!mediaToggle);

  const setAll = (newValues: AdminAndSupportLayoutContextUpdateParams) => {
    setTitle(newValues.title);
    setTitleIcon(newValues.titleIcon);
    setTitleAction(newValues.titleAction);
    setBreadcrumbsTitle(newValues.breadcrumbsTitle);
    setBreadcrumbs(newValues.breadcrumbs);
  };

  const getMainCardClassName = () => {
    if (location.pathname === '/a') return 'p-4';
    else return 'bg-white shadow-md p-4';
  };

  return userContext.isUserType([UserTypes.ADMIN, UserTypes.SUPPORT]) ? (
    <AdminAndSupportLayoutContext.Provider
      value={{
        title,
        titleIcon,
        titleAction,
        breadcrumbsTitle,
        breadcrumbs,
        setTitle,
        setTitleIcon,
        setTitleAction,
        setBreadcrumbsTitle,
        setBreadcrumbs,
        setAll,
      }}>
      <div className="">
        <div className="">
          <nav className={`si-nav  ${sidebar ? 'si-nav-max-w' : 'si-nav-min-w'}`}>
            <div className="w-full max-auto">
              <div className="flex justify-between flex-grow px-4 lg:justify-end">
                <div className="block lg:hidden">
                  <i className="pi pi-bars" onClick={toggleMedia}></i>
                </div>
                <div className="si-nav-menu">
                  <UserAccountButton isAdmin={true} />
                </div>
              </div>
            </div>
          </nav>
          <div
            className={`si si-hide-media ${sidebar ? 'si-max-w ' : 'si-min-w pr-4'} ${
              mediaToggle ? 'si-show-media ' : ''
            }  bg-[#082f18]`}>
            <div className="si-wrap">
              <div className="si-close">
                <i className="pi pi-times" onClick={toggleMedia}></i>
              </div>
              {sidebar && (
                <div className="my-8 si-logo">
                  <img src={blackLogo} alt="logo" className="w-full" />
                </div>
              )}
              <div className="hidden my-6  lg:block px-4 text-white">
                <i className=" pi pi-bars " onClick={showSidebar}></i>
              </div>
              {SidebarData.filter((item) => !item.permission || userContext.hasPermission(item.permission)).map(
                (item, index) => {
                  return <SubMenu item={item} key={index} toggleSide={toggleMedia} />;
                }
              )}
            </div>
          </div>

          <div
            className={`flex flex-col gap-4 bg-pageBackground  ${
              sidebar ? 'si-nav-max-w' : 'si-nav-min-w'
            } mr-auto px-4 `}>
            <div className="flex gap-3 items-center flex-wrap ">
              {breadcrumbsTitle && (
                <>
                  <p className="text-adminBreadcrumbs font-bold text-2xl">{breadcrumbsTitle}</p>
                  <div className="bg-adminBreadcrumbs w-1 self-stretch"></div>
                </>
              )}
              {breadcrumbs && (
                <div className="flex gap-1 items-center">
                  {breadcrumbs.map((b, index, array) => (
                    <div key={b.label} className="flex gap-1 items-center">
                      <div className="flex items-center text-xl">
                        {b.icon && <ClassNameInjector className="inline ml-2 text-sm h-6">{b.icon}</ClassNameInjector>}
                        {b.linkTo ? <Link to={b.linkTo}>{b.label}</Link> : <p>{b.label}</p>}
                      </div>
                      {index + 1 < array.length && <HiOutlineChevronRight className="text-lg" />}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={getMainCardClassName()}>
              {title && (
                <div className="flex justify-between items-center mb-5">
                  <div className="flex items-center text-2xl">
                    {titleIcon && <ClassNameInjector className="inline ml-2 h-8">{titleIcon}</ClassNameInjector>}
                    <span className="font-bold">{title}</span>
                  </div>
                  {titleAction}
                </div>
              )}
              <div>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {
                    navigate(pathname);
                  }}>
                  <Outlet />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminAndSupportLayoutContext.Provider>
  ) : (
    <Navigate to="/" />
  );
};

export default AdminAndSupportLayout;
