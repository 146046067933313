import { Button } from 'primereact/button';
import { HiChevronDown } from 'react-icons/hi';
import { Dialog } from 'primereact/dialog';
import { Form, Formik } from 'formik';
import LCCalendar from '../form/LCCalendar';
import { useState } from 'react';
import dayjs from 'dayjs';

export interface DialogDateRange {
  from: Date;
  to: Date;
}

interface DateRangeDialogProps {
  handleSubmit: (args: DialogDateRange) => void;
  dateRange: DialogDateRange;
}

const DateRangeDialog: React.FC<DateRangeDialogProps> = ({ dateRange, handleSubmit }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        label={
          dateRange.from || dateRange.to
            ? `${dateRange.from ? dayjs(dateRange.from).format('MMM DD YYYY') : '--'} - ${
                dateRange.to ? dayjs(dateRange.to).format('MMM DD YYYY') : 'Today'
              }`
            : 'إختيار فترة معينة'
        }
        icon={<HiChevronDown />}
        onClick={() => setVisible(true)}
        className="p-button-text p-button-plain"
      />
      <Dialog
        header="اختر فترة"
        closable={true}
        className="font-main dialog w-fit"
        contentClassName="text-lg"
        resizable={false}
        draggable={false}
        dismissableMask={true}
        visible={visible}
        onHide={() => setVisible(false)}>
        <Formik
          initialValues={{ from: dateRange.from, to: dateRange.to }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
            setVisible(false);
          }}>
          {({ values, handleChange, isSubmitting }) => (
            <Form className="flex flex-col items-center justify-center gap-4 m-8">
              <div className="field">
                <label htmlFor="from">من</label>
                <LCCalendar
                  maxDate={values.to}
                  id="from"
                  name="from"
                  value={values.from}
                  onChange={handleChange}
                  onSelect={(values.to = null)}
                />
              </div>
              <div className="field ">
                <label htmlFor="to">إلى</label>
                <LCCalendar minDate={values.from} id="to" name="to" value={values.to} onChange={handleChange} />
              </div>

              <Button className="btn  w-full" type="submit" disabled={isSubmitting} label="تحديث" />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default DateRangeDialog;
