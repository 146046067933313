import { Field, Form, Formik } from 'formik';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';

interface LCSearchProps {
  search?: string;
  loading: boolean;
  onSubmit: (search: string) => void;
}

const LCSearch = ({ search, loading, onSubmit }: LCSearchProps) => {
  return (
    <Formik enableReinitialize initialValues={{ search: search || '' }} onSubmit={(values) => onSubmit(values.search)}>
      {() => (
        <Form className="flex">
          <Field
            type="text"
            name="search"
            placeholder="بحث"
            className="border border-gray-200 rounded-r-md px-2 outline-none disabled:bg-gray-200 bg-white py-2"
            disabled={loading}
          />
          <button
            type="submit"
            disabled={loading}
            className="border border-r-0 border-gray-200 rounded-l-md px-2 lg:px-4 py-2 bg-[#eeeeee]">
            {loading ? <AiOutlineLoading3Quarters className="animate-spin" /> : <HiOutlineSearch />}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default LCSearch;
