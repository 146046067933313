import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import { QuestionsApi } from '../../api/questionsApi';
import icon_chat from '../../assets/icons/icon_chat.svg';
import icon_conversation from '../../assets/icons/icon_conversation.svg';
import icon_copy from '../../assets/icons/icon_copy.svg';
import icon_filter_list from '../../assets/icons/icon_filter_list.svg';
import icon_settings from '../../assets/icons/icon_settings.svg';
import LCCalendar from '../../components/form/LCCalendar';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCRadioButton from '../../components/form/LCRadioButton';
import QuestionCard from '../../components/QuestionCard';
import { GetCategories, ListQuestionsBankRequest, ListQuestionsBankResponseItem } from '../../models/questionsApi';
import { getFullName } from '../../utility/stringUtility';
import './QuestionBank.css';
import QuestionBankListMobileFilterDialog, {
  QuestionBankListMobileFilterDialogAttributes,
} from './QuestionBankListMobileFilterDialog';
import { PAGINATION_LIMIT } from '../../models/pagination';
import LCButton from '../../components/form/LCButton';

const QuestionBankList = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<ListQuestionsBankResponseItem[]>([]);
  const mobileFilterDialog = useRef<QuestionBankListMobileFilterDialogAttributes>(null);
  const [secLoading, setSecLoading] = useState(true);
  const [categories, setCategories] = useState<GetCategories[]>([]);
  const [category, setCategory] = useState<number>();
  const [subcategory, setSubCategory] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const tag = queryParams.get('tag');
  const [total, setTotal] = useState(0);
  const [anyMoreResults, setAnyMoreResults] = useState(false);

  const [request, setRequest] = useState<ListQuestionsBankRequest>({
    searchParams: {
      term: '',
      from: null,
      to: null,
      types: [],
      categoryId: category,
      tagTerm: tag?.length > 1 ? [tag] : [],
      tag: tag?.length > 1 ? true : false,
    },
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  const handleScroll = () => {
    var remainingSpace =
      document.documentElement.scrollHeight - (window.innerHeight + document.documentElement.scrollTop);

    var thresHold = 350;

    if (remainingSpace <= thresHold) {
      handleOnLoadMore();
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getQuestionsList(request);
    getCategories();
  }, []);
  useEffect(() => {
    getQuestionsList(request);
    getCategories();
  }, [request]);

  useEffect(() => {
    categories.filter((v) => {
      if (v.id === category) {
        setSubCategory([...subcategory, ...v.subCategory]);
      }
    });
    return () => {};
  }, [category]);

  const isHashtag = (value?: any) => {
    if (typeof value !== undefined) return value;
  };
  const getCategories = () => {
    QuestionsApi.getCategories().then(
      (res) => {
        setCategories(res.data.data);
        setSecLoading(false);
      },
      () => {
        setSecLoading(false);
      }
    );
  };

  const resetFilters = async () => {
    setCategory(null);
    setSubCategory([]);
    getQuestionsList(
      {
        searchParams: {
          term: '',
          from: null,
          to: null,
          types: [],
          categoryId: null,
          tagTerm: [],
          tag: false,
        },
        offset: 0,
        limit: PAGINATION_LIMIT,
      },
      null
    );
  };

  const getQuestionsList = (request?: ListQuestionsBankRequest, setSubmitting?: (submitting: boolean) => void) => {
    const body: ListQuestionsBankRequest = request
      ? request
      : {
          searchParams: {
            term: '',
            from: null,
            to: null,
            types: [],
            categoryId: category,
            tagTerm: tag?.length > 1 ? [tag] : [],
            tag: tag?.length > 1 ? true : false,
          },
          offset: 0,
          limit: PAGINATION_LIMIT,
        };

    QuestionsApi.listQuestionsBank(body).then(
      (res) => {
        setQuestions(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
        setAnyMoreResults(!(res.data.data.length >= PAGINATION_LIMIT));
        //console.log(`setting AnyMoreResults to ${anyMoreResults}, ${res.data.data.length} >= ${PAGINATION_LIMIT}`);
        setSubmitting && setSubmitting(false);
      },
      () => {
        setLoading(false);
        setSubmitting && setSubmitting(false);
      }
    );
  };

  const getConsultantFullName = (question: ListQuestionsBankResponseItem) => {
    if (question.answers.length > 0) {
      if (question.answers[0]?.editeAnswer)
        return getFullName({
          firstName: question.reviews[0]?.user.firstName,
          lastName: question.reviews[0]?.user.lastName,
        });
      else if (question.answers[0].user.isTeam)
        return getFullName({
          firstName: question.reviews[0]?.user.firstName,
          lastName: question.reviews[0]?.user.lastName,
        });
      else if (question.reviews[question.reviews.length - 1]?.changedByJudge)
        return getFullName({
          firstName: question.reviews[0]?.user.firstName,
          lastName: question.reviews[0]?.user.lastName,
        });
      else {
        return getFullName({
          firstName: question.answers[0].user.firstName,
          lastName: question.answers[0].user.lastName,
        });
      }
    } else return '';
  };

  const getAnswerContent = (question: ListQuestionsBankResponseItem) => {
    if (question.answers.length > 0)
      return question.answers[0].adminContent || question?.reviews[0]?.judgeAnswer || question.answers[0].content;
    else return '';
  };
  const handleOnLoadMore = async () => {
    //console.log(`Checking AnyMoreResults: ${anyMoreResults}`);
    if(!anyMoreResults)
      return;
    setRequest((prev) => ({ ...prev, offset: prev.offset + PAGINATION_LIMIT }));
    document.documentElement.scrollTop = 200
  };
  return (
    <>
      <Formik
        initialValues={{
          term: '',
          tagTerm: [],
          types: [],
          from: null,
          to: null,
          categoryId: category,
        }}
        onSubmit={(values, { setSubmitting }) => {
          let tag = false;
          let tagTerm;
          values.tagTerm = [];
          if (values.term.includes('#')) {
            tag = true;
            tagTerm = values.term
              .split('#')
              .map((r) => {
                if (r.length !== 0) return r.replace(/\s+/g, '');
              })
              .filter(isHashtag);
            values.tagTerm = tagTerm;
            values.term = '';
          }
          const request: ListQuestionsBankRequest = {
            searchParams: {
              tag,
              ...values,
              from: values.from
                ? dayjs(values.from).format('YYYY-MM-DD')
                : dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
              to: values.to ? dayjs(values.to).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
              categoryId: category,
            },
          };
          getQuestionsList(request, setSubmitting);
        }}>
        {({ isSubmitting, values, handleChange, submitForm }) => (
          <Form className="grow flex flex-col gap-3 px-4 lg:px-16 py-4 lg:py-8">
            <div className="flex gap-5 items-center text-4xl">
              <img src={icon_chat} alt="Icon" className="h-10" />
              <h1>خزانة الاستشارات</h1>
            </div>
            <div className="flex gap-2 items-center mt-5"></div>
            {/* <p className="hidden lg:block text-xl">
            مجموعة من إجابات الاستشارات منتقاة وفق معايير، تيسر للمستفيد الوصول للمعلومة اللغوية بأقل وقت وجهد.
          </p> */}

            <div className="flex lg:hidden bg-[#f5f5f5] rounded-md mb-5">
              <Field type="text" name="term" placeholder="ابحث عن استشارة" className="h-full p-3 bg-transparent grow" />
              <button
                type="button"
                className="p-1 px-5 hover:bg-[#00000008]"
                onClick={() => mobileFilterDialog.current.show()}>
                <img src={icon_filter_list} alt="icon filter" />
              </button>
            </div>

            <QuestionBankListMobileFilterDialog
              ref={mobileFilterDialog}
              values={values}
              handleChange={handleChange}
              resetForm={resetFilters}
              handlesubCategries={(e: any) => {
                setCategory(+e.target.value);
                setSubCategory([]);
                values.types.splice(0, values.types.length);
              }}
              submitForm={submitForm}
              isSubmitting={isSubmitting}
              categories={categories}
              subCategories={subcategory}
            />

            <div className="flex flex-col lg:flex-row gap-4">
              <div className="hidden lg:block shrink-0 bg-[#fcfcfc] rounded px-5 py-7">
                <div className="flex gap-2 items-center text-primary">
                  <img src={icon_settings} alt="Icon" className="h-4" />
                  <p className="text-2xl font-bold">تصفية النتائج</p>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center mt-5">
                    <img src={icon_conversation} alt="Icon" className="h-4" />
                    <p className="text-lg">مجال الاستشارة</p>
                  </div>

                  {secLoading ? (
                    <ProgressSpinner />
                  ) : (
                    categories.map((v, i) => (
                      <LCRadioButton
                        name="categoryId"
                        checked={category == v.id}
                        key={i}
                        id="categoryId"
                        label={v.name}
                        value={`${v.id}`}
                        onChange={(e) => {
                          setCategory(v.id);
                          setSubCategory([]);
                          values.types.splice(0, values.types.length);
                          submitForm();
                        }}
                      />
                    ))
                  )}
                  {subcategory.map((v, i) => (
                    <LCCheckbox
                      key={i}
                      value={`${v?.id}`}
                      name="types"
                      id="types"
                      formik={true}
                      label={v?.name}
                      onChange={(e) => {
                        handleChange(e);
                        submitForm();
                      }}
                      //disabled={!editMode}
                    />
                  ))}

                  <div className="flex gap-2 items-center mt-5">
                    <img src={icon_copy} alt="Icon" className="h-4" />
                    <p className="text-lg">تاريخ الاستشارة</p>
                  </div>
                  <div className="grid grid-cols-2-form items-center gap-1">
                    <p>من</p>
                    <LCCalendar
                      id="from"
                      name="from"
                      maxDate={new Date()}
                      value={values.from}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <p>إلى</p>
                    <LCCalendar
                      id="to"
                      name="to"
                      maxDate={new Date()}
                      minDate={new Date(values.from)}
                      value={values.to}
                      onChange={(e) => {
                        handleChange(e);
                        submitForm();
                      }}
                    />
                  </div>
                  <div className="hidden lg:flex bg-[#f5f5f5] rounded-md mb-5">
                    <LCButton
                      type="reset"
                      label="اعادة تعيين التصفية"
                      buttonStyle="primary"
                      disabled={isSubmitting}
                      onClick={() => resetFilters()}
                    />
                  </div>
                </div>
              </div>

              <div className="grow">
                <div className="hidden lg:flex bg-[#f5f5f5] rounded-md mb-5">
                  <Field
                    type="text"
                    name="term"
                    placeholder="ابحث عن استشارة"
                    className="h-full p-2 bg-transparent grow"
                  />
                  <button type="submit" className="p-1 px-5 hover:bg-gray-300 transition-all" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <AiOutlineLoading3Quarters className="text-xl animate-spin" />
                    ) : (
                      <HiOutlineSearch className="text-xl" />
                    )}
                  </button>
                </div>

                {loading ? (
                  <ProgressSpinner className="block mx-auto" />
                ) : (
                  <div className="grid grid-col-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-4 lg:gap-y-6 gap-y-8">
                    {questions.length ? (
                      questions.map((question) => (
                        <QuestionCard
                          key={question.id}
                          id={question.id}
                          title={question.title}
                          questionDate={question.createdAt}
                          questionContent={question.content}
                          consultantName={getConsultantFullName(question)}
                          answerContent={getAnswerContent(question)}
                          linkTo={question.id.toString()}
                          linkType="button"
                          tags={question.tags}
                        />
                      ))
                    ) : (
                      <p className="text-lg font-main">لا توجد استشارة</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default QuestionBankList;
