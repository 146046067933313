import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { PAGINATION_LIMIT } from '../../models/pagination';
import './dashboard.css';
import { DateRangeI } from '../../pages/Reports';
import icon_folders from '../../assets/icons/icon_folders.svg';
import EmptyStateImage from '../../assets/images/reports_empty_state_image.svg';
import { QuestionsApi } from '../../api/questionsApi';
import { ListLateAnswersRequets, ListLateAnswersResponseItem } from '../../models/questionsApi';
interface BoxLateAnswersProps {
  dateRange: DateRangeI;
  setTableLoading: (isLoading: boolean) => void;
}

const BoxLateAnswers: React.FC<BoxLateAnswersProps> = ({ dateRange, setTableLoading }) => {
  const [loading, setLoading] = useState(false);
  const [lateAnswers, setLateAnswers] = useState<ListLateAnswersResponseItem[]>([]);
  const [request, setRequest] = useState<ListLateAnswersRequets>({
    offset: 0,
    limit: PAGINATION_LIMIT,
    to: dateRange.to,
    from: dateRange.from,
  });

  useEffect(() => {
    resetLateAnswers();
  }, [dateRange]);

  useEffect(() => {
    loadLateAnswers();
  }, [request]);

  useEffect(() => {
    setTableLoading(loading);
  }, [loading, setTableLoading]);

  const loadLateAnswers = () => {
    setLoading(true);
    QuestionsApi.listLateAnswers(request).then(
      (res) => {
        setLateAnswers(lateAnswers.concat(res.data));
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const resetLateAnswers = () => {
    setLateAnswers([]);
    setRequest((old) => {
      return { ...old, offset: 0, limit: PAGINATION_LIMIT, ...dateRange };
    });
  };

  const handleOnLoadMore = () => {
    setRequest((old) => {
      return { ...old, offset: old.offset + old.limit, limit: PAGINATION_LIMIT };
    });
  };

  const shouldShowLoadMoreButton = () => true;

  return (
    <Card className="d-t">
      <div className="flex items-center gap-2 pt-3 px-3">
        <div>
          <img src={icon_folders} alt="icon" className="h-8" />
        </div>
        <h2 className="d-c-title">احصائيات الاستشارات المتأخرة</h2>
      </div>
      {lateAnswers.length == 0 ? (
        <img src={EmptyStateImage} height="80vh" width="100%" />
      ) : (
        <table>
          <thead>
            <tr>
              <th>رقم الإستشارة </th>
              <th> المستشار</th>
              <th>المستشار المحكم</th>
              <th>وقت إسناد الإستشارة</th>
              <th> وقت إجابة الإستشارة</th>
              <th>وقت تحكيم الإستشارة </th>
            </tr>
          </thead>
          <tbody>
            {lateAnswers.map((lateAnswer) => {
              return (
                <tr key={lateAnswer.id}>
                  <td>{lateAnswer.id}</td>
                  <td style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '200px' }}>
                    {lateAnswer.consultant}
                  </td>
                  <td style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '200px' }}>
                    {lateAnswer.judgeConsultant}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lateAnswer.idleTime}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lateAnswer.answerTime}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{lateAnswer.reviewTime}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {shouldShowLoadMoreButton() && (
        <button className="mt-4" onClick={handleOnLoadMore} disabled={loading}>
          {!loading && <span>عرض المزيد</span>}
          {loading && <AiOutlineLoading3Quarters className="text-xl animate-spin" />}
        </button>
      )}
    </Card>
  );
};

export default BoxLateAnswers;
