import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../../api/questionsApi';
import QuestionForEditHashtagCard from '../../../components/consultantsHome/QuestionForEditHashtagCard';
import { PAGINATION_LIMIT } from '../../../models/pagination';
import {
  ListQuestionsForAnswerRequest,
  ListQuestionsForAnswerResponseItem,
  ListQuestionsWithoutHashTagResponseItem,
  QuestionStatuses,
} from '../../../models/questionsApi';
import LCButton from '../../../components/form/LCButton';

const QuestionsForEditHashtag = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<ListQuestionsWithoutHashTagResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListQuestionsForAnswerRequest>({
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    loadQuestions();
  }, [request]);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.listQuestionsForWithoutHashtag(request).then(
      (res) => {
        setQuestions(questions.concat(res.data));
        // setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const resetQuestions = () => {
    setQuestions([]);
    setTotal(0);
    setRequest({ offset: 0, limit: PAGINATION_LIMIT });
  };

  const afterUpdate = (id: number) => {
    const qes = [];
    for (let i of questions) {
      if (i.id != id) {
        qes.push(i);
      }
    }
    setQuestions(qes);
  };

  const handleOnLoadMore = () => {
    setRequest({ ...request, offset: request.offset + request.limit });
  };

  return (
    <>
      <p className="cl-p">يمكنك تصفح الاستشارات التي لا تحتوي على وسوم من خلال هذه </p>
      {loading && <ProgressSpinner />}

      {questions?.map((question) => (
        <QuestionForEditHashtagCard
          key={question?.id}
          id={question?.id}
          title={question?.title}
          date={question?.createdAt}
          content={question?.content}
          afterUpdate={afterUpdate}
          isJudge={false}
          status={question?.status}
          link={`/question-for-answer/${question?.id.toString()}`}
          questionRejectedCallback={resetQuestions}
          questionReassignedCallback={resetQuestions}
        />
      ))}

      <div className="cl-btn">
        <LCButton
          type="button"
          label="عرض المزيد"
          buttonStyle="roundedStyle1"
          className="self-center mt-5 md:px-20"
          onClick={handleOnLoadMore}
          loading={loading}
          disabled={loading}
        />
      </div>
    </>
  );
};

export default QuestionsForEditHashtag;
