export enum QuestionStatuses {
  NEW = 'NEW',
  ASSIGNED_TO_CONSULTANT = 'ASSIGNED_TO_CONSULTANT',
  ANSWERED = 'ANSWERED',
  REJECTED = 'REJECTED',
  REASSIGNED = 'REASSIGNED',
  ASSIGNED_TO_JUDGE = 'ASSIGNED_TO_JUDGE',
  CERTIFIED = 'CERTIFIED',
  RETURNED_TO_CONSULTANT = 'RETURNED_TO_CONSULTANT',
}

export enum QuestionsSummaryStatusEnum {
  NEW = 'NEW',
  ASSIGNED_TO_CONSULTANT = 'ASSIGNED_TO_CONSULTANT',
  ANSWERED = 'ANSWERED',
  REJECTED = 'REJECTED',
  REASSIGNED = 'REASSIGNED',
  ASSIGNED_TO_JUDGE = 'ASSIGNED_TO_JUDGE',
  CERTIFIED = 'CERTIFIED',
  RETURNED_TO_CONSULTANT = 'RETURNED_TO_CONSULTANT',
  LATE = 'LATE',
}

export enum AnswerStatuses {
  ASSIGNED_TO_CONSULTANT = 'ASSIGNED_TO_CONSULTANT',
  ANSWERED = 'ANSWERED',
  REJECTED = 'REJECTED',
  REASSIGNED = 'REASSIGNED',
  RETURNED_TO_CONSULTANT = 'RETURNED_TO_CONSULTANT',
}

export enum ReviewStatuses {
  ASSIGNED_TO_JUDGE = 'ASSIGNED_TO_JUDGE',
  CERTIFIED = 'CERTIFIED',
  RETURNED_TO_CONSULTANT = 'RETURNED_TO_CONSULTANT',
  REASSIGNED = 'REASSIGNED',
}

export enum RejectionReasons {
  VIOLATES_TERMS = 'VIOLATES_TERMS',
  BAD_MANNERS = 'BAD_MANNERS',
  LINKING_BODIES_AND_PEOPLE = 'LINKING_BODIES_AND_PEOPLE',
  NO_QUESTION = 'NO_QUESTION',
  NOT_CLEAR = 'NOT_CLEAR',
  NOT_LANGUAGE_RELATED = 'NOT_LANGUAGE_RELATED',
}

export interface CreateQuestionRequest {
  title: string;
  content: string;
  email?: string;
}

export interface ListMyQuestionsRequest {
  offset?: number;
  limit?: number;
}

export interface ListMyQuestionsResponseItem {
  id: number;
  title: string;
  content: string;
  status: QuestionStatuses;
  createdAt: string;
  answeredAt: string;
  isHidden: boolean;
}

export interface ListQuestionsRequest {
  searchParam?: string;
  offset?: number;
  limit?: number;
  status?: QuestionStatuses;
  sort: string;
}

export interface ListQuestionsResponseItem {
  id: number;
  title: string;
  answeredAt: string;
  status: QuestionStatuses;
  answers: {
    user: {
      firstName: string;
      lastName: string;
    };
  }[];
}

export interface ListQuestionsForAnswerRequest {
  offset?: number;
  limit?: number;
}

export interface ListQuestionsForAnswerResponseItem {
  createdAt: string;
  question: {
    id: number;
    title: string;
    content: string;
    status: QuestionStatuses;
    createdAt: string;
  };
}
export interface ListQuestionsWithoutHashTagResponseItem {
  id: number;
  title: string;
  content: string;
  status: QuestionStatuses;
  createdAt: string;
}

export interface ListQuestionsForReviewRequest {
  offset?: number;
  limit?: number;
}

export interface ListQuestionsForReviewResponseItem {
  createdAt: string;
  question: {
    id: number;
    title: string;
    content: string;
    createdAt: string;
    status: QuestionStatuses;
    answeredAt: string;
  };
}

export interface ListQuestionsBankRequest {
  searchParams: {
    tag?: boolean;
    term: string | Array<string>;
    types: string[];
    from: string;
    to: string;
    categoryId: number;
    tagTerm?: Array<string>;
  };
  offset?: number;
  limit?: number;
}

export interface ListQuestionsBankResponseItem {
  id: number;
  title: string;
  createdAt: string;
  content: string;
  answers: {
    content: string;
    adminContent: string;
    createdAt: string;
    editeAnswer: boolean;
    user: {
      id: number;
      firstName: string;
      lastName: string;
      isTeam: boolean;
    };
  }[];
  reviews: {
    comments: string;
    createdAt: string;
    status: string;
    judgeAnswer: string;
    changedByJudge: boolean;
    user: {
      id: number;
      firstName: string;
      lastName: string;
    };
  }[];
  tags: Tags[];
}

export interface GetQuestionRequest {
  id: number;
}

export interface GetMyQuestionRequest {
  id: number;
}

export interface GetQuestionResponseAnswer {
  id: number;
  content: string;
  adminContent: string;
  status: AnswerStatuses;
  createdAt: string;
  updatedAt: string;
  user: {
    firstName: string;
    lastName: string;
    isTeam?: boolean;
  };
}

export interface GetQuestionResponseReview {
  comments: string;
  status: ReviewStatuses;
  createdAt: string;
  judgeAnswer: string;
  editeAnswer: boolean;
  changedByJudge?: boolean;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
export interface Tags {
  name: string;
}

export interface SubCategory {
  id: number;
  name: string;
}
export interface GetCategories {
  id: number;
  name: string;
  subCategory: SubCategory[];
}
export interface GetQuestionResponse {
  id: number;
  title: string;
  content: string;
  isHidden: boolean;
  createdAt: string;
  answeredAt: string;
  reviewedAt: string;
  status: QuestionStatuses;
  rejectionReason: RejectionReasons;
  type: Array<string>;
  categoryId: number;
  isEasy: boolean;
  category: {
    id: number;
    name: string;
  };
  subCategory: SubCategory[];
  answers: GetQuestionResponseAnswer[];
  reviews: GetQuestionResponseReview[];
  tags: Tags[];
}

export interface GetMyQuestionResponse {
  id: number;
  title: string;
  content: string;
  status: QuestionStatuses;
  isHidden: boolean;
  rejectionReason: RejectionReasons;
  createdAt: string;
  answeredAt: string;
  answers: {
    content: string;
    adminContent: string;
    status: AnswerStatuses;
    createdAt: string;
    updatedAt: string;
    editeAnswer: boolean;
    user: {
      id: number;
      isTeam: boolean;
      firstName: string;
      lastName: string;
    };
  }[];
  reviews: GetQuestionResponseReview[];
}

export interface GetQuestionsCountResponse {
  questionsCount: number;
}

export interface GetAnswersCountResponse {
  answersCount: number;
}

export interface GetQuestionsSummaryRequest {
  searchParams: {
    from: string;
    to: string;
  };
}

export interface GetQuestionsSummaryResponseItem {
  total: string;
  status: QuestionsSummaryStatusEnum;
}

export interface GetQuestionsCategoryCountItem {
  questionCount: string;
  subCategory: {
    name: string;
    id: number;
  };
}

export interface GetQuestionsSummaryByConsultantRequest {
  searchParams: {
    from: string;
    to: string;
  };
}

export enum GetQuestionsSummaryByConsultantStatusEnum {
  NEW = 'NEW',
  REASSIGNED = 'REASSIGNED',
  ASSIGNED_TO_JUDGE = 'ASSIGNED_TO_JUDGE',
  CERTIFIED = 'CERTIFIED',
  RETURNED_TO_CONSULTANT = 'RETURNED_TO_CONSULTANT',
  ASSIGNED_TO_CONSULTANT = 'ASSIGNED_TO_CONSULTANT',
  ANSWERED = 'ANSWERED',
  REJECTED = 'REJECTED',
}

export interface GetQuestionsSummaryByConsultantResponseItem {
  total: string;
  status: GetQuestionsSummaryByConsultantStatusEnum;
  firstName: string;
  lastName: string;
  userid: number;
}

export interface GetConsultantAnswersSummaryResponseItem {
  status: QuestionsSummaryStatusEnum;
  total: string;
}

export interface GetReviewerReviewsSummaryResponseItem {
  status: QuestionStatuses;
  total: string;
}

export interface GetBankQuestionRequest {
  id: number;
}

export interface GetBankQuestionResponse {
  id: number;
  title: string;
  content: string;
  status: QuestionStatuses;
  isHidden: boolean;
  createdAt: string;
  answeredAt: string;
  answers: {
    content: string;
    adminContent: string;
    status: AnswerStatuses;
    createdAt: string;
    updatedAt: string;
    editeAnswer: boolean;
    user: {
      id: number;
      firstName: string;
      lastName: string;
      isTeam: boolean;
    };
  }[];
  category: {
    id: number;
    name: string;
  };
  subCategory: SubCategory[];
  tags: Tags[];
  reviews: GetQuestionResponseReview[];
  nextQuestionId?: number;
  previousQuestionId?: number;
}

export interface UpdateQuestionVisibilityRequest {
  id: number;
  hidden: boolean;
}

export interface AnswerQuestionRequest {
  id: number;
  content: string;
  title: string;
  type: Array<string> | SubCategory[];
  isEasy: boolean;
  categoryId: number;
  tags?: Array<object>;
}

export interface RejectQuestionRequest {
  id: number;
  rejectionReason: string;
  rejectionReasonLabel: string;
  isJudge: boolean;
}

export interface ApproveQuestionRequest {
  id: number;
}

export interface ReturnQuestionToConsultantRequest {
  id: number;
  comments?: string;
  judgeAnswer: string;
  type: Array<string>;
  categoryId?: number;
  changedByJudge?: boolean;
}

export interface ReassignAnswerRequest {
  id: number;
}

export interface ReassignReviewRequest {
  id: number;
}

export interface AssignAnswerToConsultantRequest {
  questionId: number;
  userId: number;
}

export interface AssignReviewToConsultantRequest {
  questionId: number;
  userId: number;
}

export interface DownloadQuestionsReportRequest {
  from: string;
  to: string;
}

export interface DownloadConsultantsReportRequest {
  from: string;
  to: string;
}
export interface ListLateAnswersRequets {
  from: string;
  to: string;
  offset?: number;
  limit?: number;
}
export interface QuestionCategoryCountRequest {
  mainCatId: number;
  searchParams: {
    from: string;
    to: string;
  };
}

export interface ListLateAnswersResponseItem {
  id: number;
  consultant: string;
  judgeConsultant: string;
  idleTime: string;
  answerTime: string;
  reviewTime: string;
}
