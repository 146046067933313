import { BackendResponse } from '../models/backendResponse';
import { DownloadConsultantsReportRequest } from '../models/questionsApi';
import {
  CreateConsultantUserRequest,
  CreateConsultantUserResponse,
  CreateTechnicalSupportUserRequest,
  CreateTechnicalSupportUserResponse,
  DeleteUserRequest,
  DisableReceivingQuestionsRequest,
  DisableUserRequest,
  EnableReceivingQuestionsRequest,
  EnableUserRequest,
  GetConsultantUserRequest,
  GetConsultantUserResponse,
  GetTechnicalSupportUserRequest,
  GetTechnicalSupportUserResponse,
  GetUserRequest,
  GetUserResponse,
  GetUsersCountResponse,
  GetUsersSummaryRequest,
  GetUsersSummaryResponseItem,
  ListConsultantsToReassignAnswerResponseItem,
  ListConsultantsToReassignReviewResponseItem,
  ListConsultantUsersRequest,
  ListConsultantUsersResponseItem,
  ListLatestUsersRequest,
  ListLatestUsersResponseItem,
  ListPublicConsultantsRequest,
  ListPublicConsultantsResponseItem,
  ListTechnicalSupportUsersRequest,
  ListTechnicalSupportUsersResponseItem,
  ListUsersRequest,
  ListUsersResponseItem,
  UpdatePermissionsRequest,
  UpdatePermissionsResponse,
  UpdateUserRequest,
} from '../models/usersApi';
import { Axios } from './axios';

const baseUrl = '/users';

const listUsers = (params: ListUsersRequest) => {
  return Axios.get<BackendResponse<ListUsersResponseItem[]>>(`${baseUrl}`, { params });
};

const getUser = ({ id }: GetUserRequest) => {
  return Axios.get<BackendResponse<GetUserResponse>>(`${baseUrl}/show_user/${id}`);
};

const listTechnicalSupportUsers = (params: ListTechnicalSupportUsersRequest) => {
  return Axios.get<BackendResponse<ListTechnicalSupportUsersResponseItem[]>>(`${baseUrl}/support_index`, { params });
};

const getTechnicalSupportUser = ({ id }: GetTechnicalSupportUserRequest) => {
  return Axios.get<BackendResponse<GetTechnicalSupportUserResponse>>(`${baseUrl}/show_support/${id}`);
};

const createTechnicalSupportUser = (data: CreateTechnicalSupportUserRequest) => {
  return Axios.post<BackendResponse<CreateTechnicalSupportUserResponse>>(`${baseUrl}/create_support`, data);
};

const listConsultantUsers = (params: ListConsultantUsersRequest) => {
  return Axios.get<BackendResponse<ListConsultantUsersResponseItem[]>>(`${baseUrl}/consultant_index`, { params });
};

const getConsultantUser = ({ id }: GetConsultantUserRequest) => {
  return Axios.get<BackendResponse<GetConsultantUserResponse>>(`${baseUrl}/show_consultant/${id}`);
};

const createConsultantUser = (data: CreateConsultantUserRequest) => {
  return Axios.post<BackendResponse<CreateConsultantUserResponse>>(`${baseUrl}/create_consultant`, data);
};

const listPublicConsultants = (params: ListPublicConsultantsRequest) => {
  return Axios.get<BackendResponse<ListPublicConsultantsResponseItem[]>>(`${baseUrl}/public_consultants`, { params });
};

const listConsultantsToReassignAnswer = () => {
  return Axios.get<BackendResponse<ListConsultantsToReassignAnswerResponseItem[]>>(`${baseUrl}/available_consultants`);
};

const listConsultantsToReassignReview = () => {
  return Axios.get<BackendResponse<ListConsultantsToReassignReviewResponseItem[]>>(`${baseUrl}/available_judges`);
};

const listLatestUsers = (params: ListLatestUsersRequest) => {
  return Axios.get<BackendResponse<ListLatestUsersResponseItem[]>>(`${baseUrl}/latest_users_report`, { params });
};

const getUsersCount = () => {
  return Axios.get<BackendResponse<GetUsersCountResponse>>(`${baseUrl}/users_count`);
};

const enableUser = ({ id }: EnableUserRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/enable`);
};
const deleteUser = (data: DeleteUserRequest) => {
  return Axios.delete<BackendResponse<{}>>(`${baseUrl}`, { data });
};

const disableUser = ({ id }: DisableUserRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/disable`);
};

const enableReceivingQuestions = ({ id }: EnableReceivingQuestionsRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/receive_questions/enable`);
};

const disableReceivingQuestions = ({ id }: DisableReceivingQuestionsRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/${id}/receive_questions/disable`);
};

const updateUser = (data: UpdateUserRequest) => {
  return Axios.put<BackendResponse<{}>>(`${baseUrl}/${data.id}`, data);
};

const updatePermissions = (data: UpdatePermissionsRequest) => {
  return Axios.put<BackendResponse<UpdatePermissionsResponse>>(`${baseUrl}/${data.id}/permissions`, data);
};
const downloadConsultantUsersReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/consultant_user`, data, { responseType: 'blob' });
};
const downloadUsersReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/reports/users`, data, { responseType: 'blob' });
};

const downloadLatestUsersReport = (data?: DownloadConsultantsReportRequest) => {
  return Axios.post<Blob>(`${baseUrl}/latest_users_report_download`, data, { responseType: 'blob' });
};
const getUsersSummary = (data: GetUsersSummaryRequest) => {
  return Axios.post<BackendResponse<GetUsersSummaryResponseItem[]>>(`${baseUrl}/users_summary`, data);
};
export const UsersApi = {
  listUsers,
  getUser,
  listTechnicalSupportUsers: listTechnicalSupportUsers,
  getTechnicalSupportUser: getTechnicalSupportUser,
  createTechnicalSupportUser,
  listConsultantUsers,
  getConsultantUser,
  createConsultantUser,
  listPublicConsultants,
  listConsultantsToReassignAnswer,
  listConsultantsToReassignReview,
  listLatestUsers,
  getUsersCount,
  enableUser,
  disableUser,
  enableReceivingQuestions,
  disableReceivingQuestions,
  updateUser,
  updatePermissions,
  downloadConsultantUsersReport,
  downloadUsersReport,
  getUsersSummary,
  downloadLatestUsersReport,
  deleteUser,
};
