import { Route, Routes } from 'react-router';
import AdminAndSupportLayout from './layouts/AdminAndSupportLayout';
import LoginLayout from './layouts/LoginLayout';
import MainLayout from './layouts/MainLayout';
import MainWithoutNavbarLayout from './layouts/MainWithoutNavbarLayout';
import ActivateAccount from './pages/ActivateAccount';
import ConsultantsPublicList from './pages/ConsultantsPublicList';
import ConsultantUserDetails from './pages/consultantUsers/ConsultantUserDetails';
import ConsultantUsersList from './pages/consultantUsers/ConsultantUsersList';
import CreateQuestion from './pages/createQuestion/CreateQuestion';
import Dashboard from './pages/dashboard/Dashboard';
import ConsultantsHome from './pages/home/consultantsHome/ConsultantsHome';
import Home from './pages/home/Home';
import Library from './pages/Library';
import Login from './pages/Login';
import MyQuestionDetails from './pages/myQuestions/MyQuestionDetails';
import MyQuestionList from './pages/myQuestions/MyQuestionList';
import NotFound from './pages/NotFound';
import QuestionBankDetails from './pages/questionBank/QuestionBankDetails';
import QuestionBankList from './pages/questionBank/QuestionBankList';
import QuestionDetails from './pages/questions/QuestionDetails';
import QuestionsList from './pages/questions/QuestionsList';
import QuestionsForAnswerDetails from './pages/questionsForAnswer/QuestionsForAnswerDetails';
import QuestionsForReviewDetails from './pages/questionsForReview/QuestionsForReviewDetails';
import Register from './pages/register/Register';
import Reports from './pages/Reports';
import RequestPasswordReset from './pages/RequestPasswordReset';
import ResetPassword from './pages/ResetPassword';
import TechnicalSupportUserDetails from './pages/technicalSupportUsers/TechnicalSupportUserDetails';
import TechnicalSupportUsersList from './pages/technicalSupportUsers/TechnicalSupportUsersList';
import UserDetails from './pages/users/UserDetails';
import UsersList from './pages/users/UsersList';
import AuthenticatedGuard from './routeGuards/AuthenticatedGuard';
import UnauthenticatedGuard from './routeGuards/UnauthenticatedGuard';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

ReactGA.initialize('DG8LFKV4DS');

function App() {
  useEffect(() => {
    ReactGA.send({
      hintType: 'pageView',
      page: window.location.pathname + window.location.search,
      title: 'Home Page',
    });
  }, []);
  return (
    <Routes>
      <Route element={<MainWithoutNavbarLayout />}>
        <Route index element={<Home />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path="bank">
          <Route index element={<QuestionBankList />} />
          <Route path=":id" element={<QuestionBankDetails />} />
        </Route>
        <Route path="new-question" element={<CreateQuestion />} />
        <Route path="new-question/success" element={<CreateQuestion />} />
        <Route path="consultants" element={<ConsultantsPublicList />} />
        <Route path="library" element={<Library />} />

        <Route element={<AuthenticatedGuard />}>
          <Route path="c" element={<ConsultantsHome />} />
          <Route path="my-questions">
            <Route index element={<MyQuestionList />} />
            <Route path=":id" element={<MyQuestionDetails />} />
          </Route>
          <Route path="question-for-answer">
            <Route path=":id" element={<QuestionsForAnswerDetails />} />
          </Route>
          <Route path="question-for-review">
            <Route path=":id" element={<QuestionsForReviewDetails />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="activate-account/:token" element={<ActivateAccount />} />
        <Route path="register" element={<Register />} />
        <Route element={<UnauthenticatedGuard />}>
          <Route path="login" element={<Login isAdmin={false} />} />
          <Route path="admin-login" element={<Login isAdmin={true} />} />
          <Route path="request-password-reset" element={<RequestPasswordReset />} />
        </Route>
      </Route>

      <Route path="a" element={<AdminAndSupportLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="users">
          <Route index element={<UsersList />} />
          <Route path=":id" element={<UserDetails />} />
        </Route>
        <Route path="technical-support-users">
          <Route index element={<TechnicalSupportUsersList />} />
          <Route path=":id" element={<TechnicalSupportUserDetails />} />
        </Route>
        <Route path="consultant-users">
          <Route index element={<ConsultantUsersList />} />
          <Route path=":id" element={<ConsultantUserDetails />} />
        </Route>
        <Route path="questions">
          <Route index element={<QuestionsList />} />
          <Route path=":id" element={<QuestionDetails />} />
        </Route>
        <Route path="reports" element={<Reports />} />
      </Route>
    </Routes>
  );
}

export default App;
