import React, { useImperativeHandle } from 'react';
import { QuestionsApi } from '../api/questionsApi';
import { lcConfirmPopup } from '../utility/lcConfirmPopup';

interface ApproveQuestionPopupProps {
  id: number;
  setLoading?: (loading: boolean) => void;
  successCallback?: () => void;
}

export interface ApproveQuestionPopupAttributes {
  show: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ApproveQuestionPopup = React.forwardRef<ApproveQuestionPopupAttributes, ApproveQuestionPopupProps>(
  ({ id, setLoading, successCallback }, ref) => {
    useImperativeHandle(ref, () => {
      return {
        show: (event: React.MouseEvent<HTMLButtonElement>) => showConfirmPopup(event),
      };
    });

    const showConfirmPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
      lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من اعتماد هذه الاستشارة؟', () => {
        setLoading(true);
        QuestionsApi.approveQuestion({ id }).then(
          () => {
            setLoading(false);
            if (successCallback) successCallback();
          },
          () => {
            setLoading(false);
          }
        );
      });
    };

    return <></>;
  }
);

export default ApproveQuestionPopup;
