import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import { UserContext } from '../contexts/UserContext';

const UnauthenticatedGuard = () => {
  const { isLoggedIn } = useContext(UserContext);
  return !isLoggedIn() ? <Outlet /> : <Navigate to="/" />;
};

export default UnauthenticatedGuard;
