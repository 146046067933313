import { ErrorMessage, Field } from 'formik';
import React from 'react';
import './LCCheckbox.css';

interface LCCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: any;
  formik?: boolean;
  color?: 'green' | 'black';
}

const LCCheckbox = ({ label, id, name, className, formik = false, color = 'green', ...props }: LCCheckboxProps) => {
  return (
    <div>
      <div className="flex gap-2 items-center">
        {formik ? (
          <Field
            id={id}
            name={name}
            type="checkbox"
            className={`lc-checkbox lc-checkbox-${color} ${className}`}
            {...props}
          />
        ) : (
          <input
            id={id}
            name={name}
            type="checkbox"
            className={`lc-checkbox lc-checkbox-${color} ${className}`}
            {...props}
          />
        )}
        <label htmlFor={id} className="font-main">
          {label}
        </label>
      </div>
      {formik && <ErrorMessage name={name} className="text-red-600 inline-block mb-2 font-main" component="span" />}
    </div>
  );
};

export default LCCheckbox;
