import { ErrorMessage, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import React, { useImperativeHandle, useState } from 'react';
import * as Yup from 'yup';
import { QuestionsApi } from '../../api/questionsApi';
import LCButton from '../../components/form/LCButton';
import LCRadioButton from '../../components/form/LCRadioButton';
import './questionsForAnswer.css';

interface RejectQuestionDialogProps {
  id: number;
  questionRejectedCallback: () => void;
  isJudge?: boolean;
}

export interface RejectQuestionDialogAttributes {
  show: () => void;
}

const RejectQuestionDialog = React.forwardRef<RejectQuestionDialogAttributes, RejectQuestionDialogProps>(
  ({ id, questionRejectedCallback, isJudge }, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
      return {
        show: () => setVisible(true),
      };
    });

    return (
      <Dialog
        header={
          <>
            <span className="h-1 w-20 block bg-black rounded-md mx-auto lg:hidden"></span>
            <div className="flex w-3/5 gap-2 mt-4 lg:mt-0">
              <h1 className="text-3xl font-bold">الاعتذار من الإجابة</h1>
              <div className="h-1 grow bg-black relative self-end bottom-3 hidden lg:block"></div>
            </div>
          </>
        }
        closable={true}
        className="m-0 lg:m-5 w-full md:w-1/2 font-main dialog "
        contentClassName="text-lg"
        resizable={false}
        draggable={false}
        dismissableMask={true}
        visible={visible}
        onHide={() => setVisible(false)}>
        <Formik
          initialValues={{
            rejectionReason: '',
            label: '',
          }}
          validationSchema={Yup.object({
            rejectionReason: Yup.string().required('إلزامي'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            // alert(isJudge);
            QuestionsApi.rejectQuestion({
              id,
              rejectionReason: values.rejectionReason,
              rejectionReasonLabel: values.label,
              isJudge,
            }).then(
              () => {
                setSubmitting(false);
                questionRejectedCallback();
                setVisible(false);
              },
              () => {
                setSubmitting(false);
              }
            );
          }}>
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-1">
              <p className="font-bold">الرجاء اختيار مبرر الاعتذار من الإجابة</p>

              <p>
                عند اختيار مبرر الاعتذار من الإجابة سيرسل بريد إلكتروني للمستشير بالاعتذار عن الإجابة مع مبرر الاعتذار
              </p>

              <div className="mt-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2">
                  <LCRadioButton
                    label="مخالف للسياسات والأحكام"
                    name="rejectionReason"
                    value={'مخالف للسياسات والأحكام'}
                  />
                  <LCRadioButton label="أسلوب غير مناسب" name="rejectionReason" value={'أسلوب غير مناسب'} />
                  <LCRadioButton label="المساس بهيئات وأشخاص" name="rejectionReason" value={'المساس بهيئات وأشخاص'} />
                  <LCRadioButton label="لا يوجد استشارة " name="rejectionReason" value={'لا يوجد استشارة '} />
                  <LCRadioButton label="الاستشارة غير مفهومة" name="rejectionReason" value={'الاستشارة غير مفهومة'} />
                  <LCRadioButton label="استشارة ليست لغوية" name="rejectionReason" value={'استشارة ليست لغوية'} />
                </div>
                <ErrorMessage name="rejectionReason" className="text-red-600" component="p" />
              </div>

              <LCButton
                label="إرسال"
                type="submit"
                buttonStyle="roundedStyle1"
                className="self-start mt-6"
                disabled={isSubmitting}
                loading={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
);

export default RejectQuestionDialog;
