import { UpdateAdminContentRequest } from '../models/answerApi';
import { BackendResponse } from '../models/backendResponse';
import { Axios } from './axios';

const baseUrl = '/answers';

const updateAdminContent = (data: UpdateAdminContentRequest) => {
  return Axios.put<BackendResponse<{}>>(`${baseUrl}/${data.id}/admin_answer`, data);
};

export const AnswerApi = {
  updateAdminContent,
};
