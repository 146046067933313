import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import * as Yup from 'yup';
import { AuthApi } from '../../api/authApi';
import Button from '../../components/button/Button';
import { LoginLayoutContext } from '../../contexts/LoginLayoutContext';
import { UserContext } from '../../contexts/UserContext';
import './RegisterOtp.css';

const RegisterOtp = ({ setStep, email }: { setStep: (step: string) => void; email: string }) => {
  const userContext = useContext(UserContext);
  const loginLayoutContext = useContext(LoginLayoutContext);

  useEffect(() => {
    loginLayoutContext.setTitle('التحقق من الحساب');
  }, []);

  return (
    <>
      <p className="text-justify font-main">
        لقد أرسل رمز التحقق على البريد الإلكتروني المرفق خلال عملية التسجيل. يرجى التحقق من البريد الخاص بك وإدخال الرمز
        في الخانات المخصصة له لإكمال عملية التسجيل.
      </p>

      <Formik
        initialValues={{ otp: '' }}
        validationSchema={Yup.object({
          otp: Yup.string().required('إلزامي'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          AuthApi.verifyOtp({ ...values, email }).then(
            (res) => {
              const data = res.data.data;
              userContext.setUser(data);
              setSubmitting(false);
              setStep('success');
            },
            () => {
              setSubmitting(false);
            }
          );
        }}>
        {({ isSubmitting, setValues, submitForm }) => (
          <Form className="flex flex-col items-start gap-4">
            <ReactCodeInput
              type="number"
              fields={4}
              className="verifyInput mb-4"
              onChange={(value) => setValues({ otp: value })}
              onComplete={submitForm}
              disabled={isSubmitting}
            />
            <ErrorMessage name="otp" className="text-red-600 inline-block mb-2" component="span" />

            <Button type="submit" disabled={isSubmitting}>
              تفعيل الحساب
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegisterOtp.propTypes = {
  setStep: PropTypes.func,
  email: PropTypes.string,
};

export default RegisterOtp;
