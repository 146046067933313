import { Toast } from 'primereact/toast';
import React, { useEffect, useRef } from 'react';
import './ToastComponent.css';
import { ToastManager } from './toastManager';

const ToastComponent = () => {
  const toast = useRef<Toast>();

  const showSuccess = (summary: string, detail?: string) => {
    toast.current.show({ severity: 'success', life: 5000, summary, detail });
  };

  const showError = (summary: string, detail?: string) => {
    toast.current.show({ severity: 'error', life: 5000, summary, detail });
  };

  useEffect(() => {
    ToastManager.showSuccess = showSuccess;
    ToastManager.showError = showError;
  }, []);

  return <Toast ref={toast} position="top-left" className=" left-2 lg:left-5" />;
};

export default ToastComponent;
