import avatar_1 from '../assets/images/avatar_1.png';
import { getFormattedDate, getFormattedTime } from '../utility/stringUtility';

interface UserContentCardProps {
  date: string;
  content: string;
  className?: string;
  backgroundColorClass?: string;
  dateStyle?: 'dateOnly' | 'dateAndTime';
}

const UserContentCard = ({
  date,
  content,
  className = '',
  backgroundColorClass = 'bg-[#eeeeee]',
  dateStyle = 'dateOnly',
}: UserContentCardProps) => {
  const dateElements = {
    dateOnly: (
      <p className="text-md text-right" style={{ direction: 'ltr' }}>
        {getFormattedDate(date)}
      </p>
    ),
    dateAndTime: (
      <div className="flex justify-between mb-2">
        <span style={{ direction: 'ltr' }}>{getFormattedTime(date)}</span>
        <span style={{ direction: 'ltr' }}>{getFormattedDate(date)}</span>
      </div>
    ),
  };

  return (
    <div className={`flex flex-col md:flex-row items-start gap-4 ${className}`}>
      <img src={avatar_1} alt="User avatar" className="w-8 h-8" />
      <div className={`rounded-xl lg:rounded-3xl p-3 lg:p-4 grow w-full ${backgroundColorClass}`}>
        {dateElements[dateStyle]}
        <p className="text-lg md:text-2xl lg:text-3xl">{content}</p>
      </div>
    </div>
  );
};

export default UserContentCard;
