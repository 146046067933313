import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionsApi } from '../../api/questionsApi';
import { UserContext } from '../../contexts/UserContext';
import {
  GetConsultantAnswersSummaryResponseItem,
  GetReviewerReviewsSummaryResponseItem,
  QuestionsSummaryStatusEnum,
  QuestionStatuses,
} from '../../models/questionsApi';
import { getFullName } from '../../utility/stringUtility';
import EnableDisableOwnReceivingQuestions from '../EnableDisableOwnReceivingQuestions';
import './consultantsHome.css';

interface ConsultantInfoProps {
  summaryDisplayed: 'consultant' | 'reviewer';
}

const ConsultantInfo = ({ summaryDisplayed }: ConsultantInfoProps) => {
  const userContext = useContext(UserContext);
  const [consultantSummaryLoading, setConsultantSummaryLoading] = useState(false);
  const [consultantSummaryError, setConsultantSummaryError] = useState(false);
  const [consultantSummary, setConsultantSummary] = useState({
    totalAssignedCount: 0,
    pendingCount: 0,
    answeredCount: 0,
    lateCount: 0,
    rejectedCount: 0,
    returnedCount: 0,
    certifiedCount: 0,
  });

  const [reviewerSummaryLoading, setReviewerSummaryLoading] = useState(false);
  const [reviewerSummaryError, setReviewerSummaryError] = useState(false);
  const [reviewerSummary, setReviewerSummary] = useState({
    totalAssignedCount: 0,
    pendingCount: 0,
    certifiedCount: 0,
    returnedCount: 0,
  });

  useEffect(() => {
    loadConsultantSummary();
    loadReviewerSummary();
  }, []);

  const loadConsultantSummary = () => {
    setConsultantSummaryLoading(true);
    setConsultantSummaryError(false);
    QuestionsApi.getConsultantAnswersSummary().then(
      (res) => {
        setConsultantSummaryLoading(false);
        setConsultantSummaryFromResponse(res.data.data);
      },
      () => {
        setConsultantSummaryLoading(false);
        setConsultantSummaryError(true);
      }
    );
  };

  const loadReviewerSummary = () => {
    setReviewerSummaryLoading(true);
    setReviewerSummaryError(false);
    QuestionsApi.getReviewerReviewsSummary().then(
      (res) => {
        setReviewerSummaryLoading(false);
        setReviewerSummaryFromResponse(res.data.data);
      },
      () => {
        setReviewerSummaryLoading(false);
        setReviewerSummaryError(true);
      }
    );
  };

  const setConsultantSummaryFromResponse = (response: GetConsultantAnswersSummaryResponseItem[]) => {
    const totalAssignedCount = response
      .filter(
        (item) =>
          item.status === QuestionsSummaryStatusEnum.REJECTED ||
          item.status === QuestionsSummaryStatusEnum.REASSIGNED ||
          item.status === QuestionsSummaryStatusEnum.CERTIFIED ||
          item.status === QuestionsSummaryStatusEnum.RETURNED_TO_CONSULTANT ||
          item.status === QuestionsSummaryStatusEnum.ASSIGNED_TO_CONSULTANT ||
          item.status === QuestionsSummaryStatusEnum.ANSWERED ||
          item.status === QuestionsSummaryStatusEnum.ASSIGNED_TO_JUDGE
      )
      .map((item) => Number.parseInt(item.total))
      .reduce((prev, current) => prev + current, 0);

    const pendingCount = Number.parseInt(
      response.find((item) => item.status === QuestionsSummaryStatusEnum.ASSIGNED_TO_CONSULTANT).total
    );

    const answeredCount = response
      .filter(
        (item) =>
          item.status === QuestionsSummaryStatusEnum.ANSWERED ||
          item.status === QuestionsSummaryStatusEnum.ASSIGNED_TO_JUDGE ||
          item.status === QuestionsSummaryStatusEnum.RETURNED_TO_CONSULTANT
      )
      .map((item) => Number.parseInt(item.total))
      .reduce((prev, current) => prev + current, 0);

    const lateCount = Number.parseInt(response.find((item) => item.status === QuestionsSummaryStatusEnum.LATE).total);

    const rejectedCount = Number.parseInt(
      response.find((item) => item.status === QuestionsSummaryStatusEnum.REJECTED).total
    );

    const returnedCount = Number.parseInt(
      response.find((item) => item.status === QuestionsSummaryStatusEnum.RETURNED_TO_CONSULTANT).total
    );

    const certifiedCount = Number.parseInt(
      response.find((item) => item.status === QuestionsSummaryStatusEnum.CERTIFIED).total
    );

    setConsultantSummary({
      totalAssignedCount,
      pendingCount,
      answeredCount,
      lateCount,
      rejectedCount,
      returnedCount,
      certifiedCount,
    });
  };

  const setReviewerSummaryFromResponse = (response: GetReviewerReviewsSummaryResponseItem[]) => {
    const totalAssignedCount = response
      .filter(
        (item) =>
          item.status === QuestionStatuses.REASSIGNED ||
          item.status === QuestionStatuses.CERTIFIED ||
          item.status === QuestionStatuses.RETURNED_TO_CONSULTANT ||
          item.status === QuestionStatuses.ASSIGNED_TO_JUDGE
      )
      .map((item) => Number.parseInt(item.total))
      .reduce((prev, current) => prev + current, 0);

    const pendingCount = Number.parseInt(
      response.find((item) => item.status === QuestionStatuses.ASSIGNED_TO_JUDGE).total
    );

    const certifiedCount = Number.parseInt(response.find((item) => item.status === QuestionStatuses.CERTIFIED).total);

    const returnedCount = Number.parseInt(
      response.find((item) => item.status === QuestionStatuses.RETURNED_TO_CONSULTANT).total
    );

    setReviewerSummary({
      totalAssignedCount,
      pendingCount,
      certifiedCount,
      returnedCount,
    });
  };

  const getConsultantSummary = () => {
    if (consultantSummaryLoading) {
      return <ProgressSpinner />;
    } else if (consultantSummaryError) {
      return <p>حصل خطأ أثناء تحميل ملخص الإجابات</p>;
    } else {
      return (
        <>
          <div className="ci-title">
            <p>الاستشارات الكلية المسندة</p>
            <p>{consultantSummary.totalAssignedCount}</p>
          </div>
          <div className="ci-title">
            <p>الاستشارات المسندة المعلقة</p>
            <p>{consultantSummary.pendingCount}</p>
          </div>
          <div className="ci-title-md-only">
            <p>الاستشارات المجاب عنها</p>
            <p>{consultantSummary.answeredCount}</p>
          </div>
          <div className="ci-title">
            <p>الاستشارات المتأخرة</p>
            <p>{consultantSummary.lateCount}</p>
          </div>
          <div className="ci-title-md-only">
            <p>الاستشارات المعتذر منها</p>
            <p>{consultantSummary.rejectedCount}</p>
          </div>
          <div className="ci-title-md-only">
            <p>الاستشارات المعادة</p>
            <p>{consultantSummary.returnedCount}</p>
          </div>
          <div className="ci-title">
            <p>الاستشارات المعتمدة</p>
            <p>{consultantSummary.certifiedCount}</p>
          </div>
        </>
      );
    }
  };

  const getReviewerSummary = () => {
    if (reviewerSummaryLoading) {
      return <ProgressSpinner />;
    } else if (reviewerSummaryError) {
      return <p>حصل خطأ أثناء تحميل ملخص المراجعات</p>;
    } else {
      return (
        <>
          <div className="ci-title">
            <p>عدد الاستشارات الكلية المسندة</p>
            <p>{reviewerSummary.totalAssignedCount}</p>
          </div>
          <div className="ci-title">
            <p>عدد الاستشارات المسندة المعلقة</p>
            <p>{reviewerSummary.pendingCount}</p>
          </div>
          <div className="ci-title">
            <p>عدد الاستشارات المعتمدة</p>
            <p>{reviewerSummary.certifiedCount}</p>
          </div>
          <div className="ci-title">
            <p>عدد الاستشارات المعادة</p>
            <p>{reviewerSummary.returnedCount}</p>
          </div>
        </>
      );
    }
  };

  return (
    <div className="ci-info">
      <div className="ci-header">
        <h3 className="ci-title">{getFullName(userContext)}</h3>
        <p className="text-md font-main mb-3">{userContext.position}</p>
      </div>

      <EnableDisableOwnReceivingQuestions className="block mx-auto" />

      <div className="ci-body">{summaryDisplayed === 'consultant' ? getConsultantSummary() : getReviewerSummary()}</div>

      <div className="ci-calendar ui-g ui-fluid">
        <Calendar inline className="w-full" locale="ar" readOnlyInput={true} showOtherMonths={false} disabled />
      </div>
    </div>
  );
};

export default ConsultantInfo;
