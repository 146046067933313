export enum UserTypes {
  GUEST = 'GUEST',
  USER = 'USER',
  CONSULTANT = 'CONSULTANT',
  SUPPORT = 'SUPPORT',
  ADMIN = 'ADMIN',
}

export enum Permissions {
  LIST_USERS = 'LIST_USERS',
  LIST_CONSULTANTS = 'LIST_CONSULTANTS',
  LIST_SUPPORTS = 'LIST_SUPPORTS',
  LIST_QUESTIONS = 'LIST_QUESTIONS',
  MODIFY_PERMISSIONS = 'MODIFY_PERMISSIONS',
  MODIFY_USER_STATUS = 'MODIFY_USER_STATUS',
  UPDATE_USER_INFO = 'UPDATE_USER_INFO',
  CREATE_CONSULTANTS = 'CREATE_CONSULTANTS',
  CREATE_SUPPORTS = 'CREATE_SUPPORTS',
  CREATE_QUESTIONS = 'CREATE_QUESTIONS',
  HIDE_QUESTIONS = 'HIDE_QUESTIONS',
  DELETE_QUESTIONS = 'DELETE_QUESTIONS',
  ANSWER_QUESTIONS = 'ANSWER_QUESTIONS',
  JUDGE_QUESTIONS = 'JUDGE_QUESTIONS',
  EDIT_QUESTIONS = 'EDIT_QUESTIONS'
}

export interface Permission {
  id: number;
  name: string;
  arName: string;
  UserPermission: {
    isEnabled: boolean;
  };
}
