import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'primeicons/primeicons.css';
import { addLocale, locale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ToastComponent from './components/toast/ToastComponent';
import { UserProvider } from './contexts/UserContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

// PrimeReact Configuration
addLocale('ar', {
  accept: 'نعم',
  reject: 'لا',
  choose: 'اختر',
  upload: 'رفع',
  cancel: 'إلغاء',
  dayNames: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
  dayNamesShort: ['أحد', 'إثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
  dayNamesMin: ['ح', 'إ', 'ث', 'ر', 'خ', 'ج', 'س'],
  monthNames: [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  today: 'اليوم',
  clear: 'مسح',
  weekHeader: 'أسبوع',
  firstDayOfWeek: 0,
  dateFormat: 'yy-mm-dd',
  weak: 'ضعيف',
  medium: 'وسط',
  strong: 'قوي',
  passwordPrompt: 'أدخل كلمة المرور',
});

locale('ar');

// DayJS Configuration
dayjs.extend(relativeTime);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <ToastComponent />
        <App />
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
