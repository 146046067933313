import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import avatar_2 from '../assets/images/avatar_2.png';
import { AnswerStatuses, RejectionReasons, ReviewStatuses } from '../models/questionsApi';
import { convertArNumbersToEnNumbers } from '../utility';
import { rejectionReasonMapper } from '../utility/rejectionReasonMapper';
import {
  appendAnswerPrefix,
  appendConsultantPrefix,
  getFormattedDate,
  getFormattedFullDate,
  getFormattedTime,
} from '../utility/stringUtility';
import React from 'react';

interface ConsultantContentCardProps {
  consultantName: { firstName: string; lastName: string };
  assignmentDate?: string;
  contentDate: string;
  type: 'answer' | 'review';
  status: AnswerStatuses | ReviewStatuses;
  content: string;
  /** Required if type === 'answer' */
  isContentChanged?: boolean;
  /** Required if type === 'answer' */
  rejectionReason?: RejectionReasons;
  className?: string;
  backgroundColorClass?: string;
  dateStyle?: 'dateOnly' | 'dateAndTime';
  isAdmin?: boolean;
}

const ConsultantContentCard = ({
  consultantName,
  assignmentDate,
  contentDate,
  type,
  status,
  content,
  isContentChanged,
  rejectionReason,
  className = '',
  backgroundColorClass = 'bg-[#eeeeee]',
  dateStyle = 'dateOnly',
  isAdmin = false,
}: ConsultantContentCardProps) => {
  const [questionUrl, setQuestionUrl] = useState('');
  const path = window.location.pathname;
  //console.log(path.replace(path[path.length - 1], "2"))
  useEffect(() => {
    if (isAdmin !== undefined) {
      //console.log(isAdmin)
      setQuestionUrl(isAdmin ? '/a/questions' : '/bank');
    }

    return () => {};
  }, [isAdmin]);

  const dateElements = {
    dateOnly: (
      <p className="text-md text-right" style={{ direction: 'ltr' }}>
        {getFormattedDate(contentDate)}
      </p>
    ),
    dateAndTime: (
      <div className="flex justify-between mb-2">
        <span style={{ direction: 'ltr' }}>{getFormattedTime(contentDate)}</span>
        <span style={{ direction: 'ltr' }}>{getFormattedDate(contentDate)}</span>
      </div>
    ),
  };
  const contentBuilder = (content: string) => {};
  const getContentElement = () => {
    if (type === 'answer') {
      if (
        status === AnswerStatuses.ASSIGNED_TO_CONSULTANT ||
        status === AnswerStatuses.RETURNED_TO_CONSULTANT ||
        status === AnswerStatuses.REASSIGNED ||
        status === AnswerStatuses.ANSWERED
      ) {
        if (content) {
          return (
            <>
              <p className="text-lg md:text-2xl lg:text-3xl">
                {content?.split('\n')?.map((v, key) => {
                  if (v.includes('@'))
                    return (
                      <a
                        key={key}
                        className="text-primary font-bold"
                        href={`${
                          path?.includes('/a')
                            ? `/a/questions/${convertArNumbersToEnNumbers(v?.replace('@', ''))}`
                            : `/bank/${convertArNumbersToEnNumbers(v?.replace('@', ''))}`
                        }`}>
                        {v?.replace('@', '')?.concat(' ')}{' '}
                      </a>
                    );
                  else
                    return (
                      <span key={key}>
                        {v?.split('\n').map((line, idx) => (
                          <React.Fragment key={idx}>
                            <p>{line}</p>
                            {idx < content?.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </span>
                    );
                })}
                {isContentChanged && <span className="italic text-gray-400">&nbsp;(الإجابة معدلة)</span>}
              </p>
            </>
          );
        } else {
          return <p className="text-lg md:text-2xl lg:text-3xl italic text-gray-500">لا توجد إجابة</p>;
        }
      } else if (status === AnswerStatuses.REJECTED) {
        return (
          <p className="text-lg md:text-2xl lg:text-3xl">
            الاستشارة مرفوضة بسبب :&nbsp;
            {rejectionReason}
          </p>
        );
      }
    } else if (type === 'review') {
      if (
        status === ReviewStatuses.ASSIGNED_TO_JUDGE ||
        status === ReviewStatuses.REASSIGNED ||
        status === ReviewStatuses.RETURNED_TO_CONSULTANT
      ) {
        if (content) {
          return <p className="text-lg md:text-2xl lg:text-3xl">{content}</p>;
        } else {
          return <p className="text-lg md:text-2xl lg:text-3xl italic text-gray-500">لا توجد مراجعة</p>;
        }
      } else if (status === ReviewStatuses.CERTIFIED) {
        return (
          <div className="flex items-center gap-2 text-lg md:text-2xl lg:text-3xl">
            <FaCheckCircle className="text-primaryLight" />
            <p>الإجابة معتمدة</p>
            <br />

            {content?.split('\n').map((line, idx) => (
              <React.Fragment key={idx}>
                <p>{line}</p>
                {idx < content?.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        );
      }
    } else {
      return <p className="text-lg md:text-2xl lg:text-3xl">{content}</p>;
    }
  };

  return (
    <div className={`grid grid-cols-2-form gap-x-4 gap-y-2 ${className}`}>
      <img src={avatar_2} alt="Consultant avatar" className="w-8 h-8" />
      <div className="flex flex-col lg:flex-row justify-between gap-3">
        <p className="text-xl font-bold">{appendConsultantPrefix(consultantName)}</p>
        {assignmentDate && (
          <div className="flex gap-3 text-gray-600">
            <p>وقت اسناد الاستشارة:</p>
            <p style={{ direction: 'ltr' }}>{getFormattedFullDate(assignmentDate)}</p>
          </div>
        )}
      </div>
      <div
        className={`rounded-xl lg:rounded-3xl p-4 pt-2 md:col-start-2 col-span-2 md:col-span-1 ${backgroundColorClass}`}>
        {dateElements?.[dateStyle] ? dateElements[dateStyle] : null}
        {questionUrl?.length !== 0 ? getContentElement() : <></>}
      </div>
    </div>
  );
};

export default ConsultantContentCard;
