import { useContext, useEffect } from 'react';
import { QuestionsApi } from '../../api/questionsApi';
import { UsersApi } from '../../api/usersApi';
import icon_folders from '../../assets/images/icon_folders.svg';
import icon_folder_check from '../../assets/icons/collection_folder_icon.svg';
import icon_users from '../../assets/icons/icon_users.svg';
import BoxCollection from '../../components/Dashboard/BoxCollection';
import BoxConsultants from '../../components/Dashboard/BoxConsultants';
import BoxQuestions from '../../components/Dashboard/BoxQuestions';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import './dashboard.css';
import BoxUsersChart from '../../components/Dashboard/BoxUsersChart';
import BoxQuestionCategoryChart from '../../components/Dashboard/BoxQuestionCategoryChart';
import BoxConsultantsTable from '../../components/Dashboard/BoxConsultantsTable';

const Dashboard = () => {
  const layoutContext = useContext(AdminAndSupportLayoutContext);

  useEffect(() => {
    layoutContext.setAll({});
  }, []);

  return (
    <div>
      <div className="d-box">
        <BoxCollection
          title="العدد الكلي للاستشارات"
          icon={icon_folders}
          endpoint={QuestionsApi.getQuestionsCount}
          responseFieldName="questionsCount"
        />
        <BoxCollection
          title="عدد الاستشارات المجاب عليها"
          icon={icon_folder_check}
          endpoint={QuestionsApi.getAnswersCount}
          responseFieldName="answersCount"
        />
        <BoxCollection
          title="العدد الكلي للمستخدمين"
          icon={icon_users}
          endpoint={UsersApi.getUsersCount}
          responseFieldName="usersCount"
        />
      </div>
      <div className="d-box">
        <BoxQuestions />
        <BoxUsersChart />
      </div>

      <div className="d-box">
        <BoxQuestionCategoryChart title={'النحو و الصرف'} id={1} />
        <BoxQuestionCategoryChart title={'اللغة و الدلالة'} id={2} />
      </div>
      <div className="d-box">
        <BoxQuestionCategoryChart title={'الأدب و البلاغة و النقد'} id={3} />
        <BoxQuestionCategoryChart title={'الإملاء والرسم'} id={4} />
      </div>
    </div>
  );
};

export default Dashboard;
