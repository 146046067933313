import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../../api/questionsApi';
import QuestionForReviewCard from '../../../components/consultantsHome/QuestionForReviewCard';
import LCButton from '../../../components/form/LCButton';
import { PAGINATION_LIMIT } from '../../../models/pagination';
import { ListQuestionsForReviewRequest, ListQuestionsForReviewResponseItem } from '../../../models/questionsApi';

const QuestionsForReviewList = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<ListQuestionsForReviewResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListQuestionsForReviewRequest>({
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    loadQuestions();
  }, [request]);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.listQuestionsForReview(request).then(
      (res) => {
        setQuestions(questions.concat(res.data.data));
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleOnLoadMore = () => {
    setRequest({ ...request, offset: request.offset + request.limit });
  };

  const shouldShowLoadMoreButton = () => questions.length < total;

  return (
    <>
      <p className="cl-p">يمكنك تصفح أحدث الاستشارات المسندة إليك للمراجعة من خلال هذه القائمة</p>

      {loading && !shouldShowLoadMoreButton() && <ProgressSpinner />}

      {questions.map((question) => (
        <QuestionForReviewCard
          key={question.question?.id}
          title={question.question?.title}
          date={question.question?.createdAt}
          content={question.question?.content}
          status={question.question?.status}
          link={`/question-for-review/${question.question?.id.toString()}`}
        />
      ))}

      {shouldShowLoadMoreButton() && (
        <div className="cl-btn">
          <LCButton
            type="button"
            label="عرض المزيد"
            buttonStyle="roundedStyle1"
            className="self-center mt-5 md:px-20"
            onClick={handleOnLoadMore}
            loading={loading}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};

export default QuestionsForReviewList;
