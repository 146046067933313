import { ErrorMessage, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { PermissionsApi } from '../../api/permissionsApi';
import { UsersApi } from '../../api/usersApi';
import icon_email_outlined from '../../assets/icons/icon_email_outlined.svg';
import icon_id_card from '../../assets/icons/icon_id_card.svg';
import icon_tag_circle from '../../assets/icons/icon_tag_circle.svg';
import icon_users from '../../assets/icons/icon_users.svg';
import icon_user_gear from '../../assets/icons/icon_user_gear.svg';
import LCButton from '../../components/form/LCButton';
import LCInputTextRounded from '../../components/form/LCInputTextRounded';
import LCRadioButton from '../../components/form/LCRadioButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCSimpleMessage from '../../components/LCMessage';
import { ListPermissionsResponseItem } from '../../models/permissionsApi';
import { Permissions } from '../../models/user';
import { getPermissionId } from '../../utility/permissionsUtility';
import './CreateConsultantUser.css';
import PermissionsSelector from '../../components/PermissionsSelector';

interface CreateConsultantUserProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userCreatedCallback: () => void;
}

const CreateConsultantUser = ({ visible, setVisible, userCreatedCallback }: CreateConsultantUserProps) => {
  const [userAdded, setUserAdded] = useState(false);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [permissionsLoadingError, setPermissionsLoadingError] = useState(false);
  const [permissionsList, setPermissionsList] = useState<ListPermissionsResponseItem[]>(null);

  const [enabledPermissionIds, setEnabledPermissionIds] = useState<number[]>([]);
  const [disabledPermissionIds, setDisabledPermissionIds] = useState<number[]>([]);

  useEffect(() => {
    setUserAdded(false);

    if (!visible || permissionsList || permissionsLoading) return;

    setPermissionsLoading(true);
    setPermissionsLoadingError(false);
    PermissionsApi.listPermissions().then(
      (res) => {
        setPermissionsList(res.data.data);
        setPermissionsLoading(false);
      },
      () => {
        setPermissionsLoadingError(true);
        setPermissionsLoading(false);
      }
    );
  }, [visible]);

  return (
    <Dialog
      header={
        <div className="flex w-3/5 gap-2">
          <h1 className="text-3xl font-bold">إضافة مستخدم جديد</h1>
          <div className="h-1 grow bg-black relative self-end bottom-3"></div>
        </div>
      }
      closable={false}
      className="m-5 md:w-1/2 font-main"
      contentClassName="text-lg"
      resizable={false}
      draggable={false}
      dismissableMask={true}
      visible={visible}
      onHide={() => setVisible(false)}>
      {!userAdded ? (
        <Formik
          initialValues={{ firstName: '', lastName: '', position: '', email: '', type: '', isTeam: false }}
          validationSchema={Yup.object({
            firstName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            lastName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            position: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            email: Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي'),
            type: Yup.string().required('إلزامي'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const enable: number[] = enabledPermissionIds;
            const disable: number[] = disabledPermissionIds;

            if (permissionsList && values.type === 'consultantAndReviewer') {
              const judgeQuestionsPermissionId = getPermissionId(permissionsList, Permissions.JUDGE_QUESTIONS);
              enable.push(judgeQuestionsPermissionId);
            }

            UsersApi.createConsultantUser({ ...values, enable, disable }).then(
              () => {
                setSubmitting(false);
                setUserAdded(true);
                userCreatedCallback();
              },
              () => {
                setSubmitting(false);
              }
            );
          }}>
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-6">
              <p>
                عند إضافة مستخدم جديد يرسل رابط على البريد الإلكتروني لتفعيل الحساب ثم يقوم بنقله لينشئ كلمة المرور
                الخاصة به
              </p>

              <div className="grid grid-cols-2-form gap-x-2 gap-y-4">
                <div className="flex items-center gap-2">
                  <img src={icon_id_card} alt="Icon" className="h-6" />
                  <label htmlFor="firstName" className="text-xl">
                    الاسم الأول
                  </label>
                </div>
                <LCInputTextRounded
                  id="firstName"
                  name="firstName"
                  type="text"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2">
                  <img src={icon_id_card} alt="Icon" className="h-6" />
                  <label htmlFor="lastName" className="text-xl">
                    الاسم الأخير
                  </label>
                </div>
                <LCInputTextRounded
                  id="lastName"
                  name="lastName"
                  type="text"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2">
                  <img src={icon_user_gear} alt="Icon" className="h-6" />
                  <label htmlFor="position" className="text-xl">
                    المنصب
                  </label>
                </div>
                <LCInputTextRounded
                  id="position"
                  name="position"
                  type="text"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2">
                  <img src={icon_email_outlined} alt="Icon" className="h-6" />
                  <label htmlFor="email" className="text-xl">
                    البريد الإلكتروني
                  </label>
                </div>
                <LCInputTextRounded
                  id="email"
                  name="email"
                  type="email"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2">
                  <img src={icon_tag_circle} alt="Icon" className="h-6" />
                  <label htmlFor="type" className="text-xl">
                    النوع
                  </label>
                </div>
                {permissionsLoading && <Skeleton className="h-full" />}
                {permissionsLoadingError && <p>حصل خطأ أثناء تحميل الصلاحيات، يرجى المحاولة مرة أخرى لاحقًا</p>}
                {permissionsList && (
                  <div>
                    <div className="flex gap-4">
                      <LCRadioButton label="مستشار" name="type" value="consultant" />
                      <LCRadioButton label="مستشار محكم" name="type" value="consultantAndReviewer" />
                    </div>
                    <ErrorMessage name="type" className="text-red-600" component="div" />
                  </div>
                )}

                <div className="flex items-center gap-2">
                  <img src={icon_users} alt="Icon" className="h-6" />
                  <label htmlFor="isTeam" className="text-xl">
                    الفريق العلمي
                  </label>
                </div>
                <div className="flex gap-4">
                  <LCCheckbox name="isTeam" id="isTeam" formik={true} />
                </div>
              </div>

              {permissionsList && (
                <div>
                  <p className="text-xl">الصلاحيات</p>
                  <div className="px-10">
                    <PermissionsSelector
                      permissions={permissionsList
                        // TODO: get the permissions from the backend
                        .filter((p) =>
                          [
                            Permissions.LIST_QUESTIONS,
                            Permissions.HIDE_QUESTIONS,
                            Permissions.DELETE_QUESTIONS,
                            Permissions.ANSWER_QUESTIONS,
                          ].includes(p.name as Permissions)
                        )
                        .map((permission) => ({
                          id: permission.id,
                          label: permission.arName,
                          selected: true,
                        }))}
                      onPermissionsChange={(enabledPermissionIds, disabledPermissionIds) => {
                        setEnabledPermissionIds(enabledPermissionIds);
                        setDisabledPermissionIds(disabledPermissionIds);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-row-reverse gap-2">
                <LCButton
                  label="إلغاء"
                  type="button"
                  buttonStyle="roundedStyle1Outlined"
                  onClick={() => setVisible(false)}
                />
                <LCButton
                  label="إضافة"
                  type="submit"
                  buttonStyle="roundedStyle1"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <LCSimpleMessage type="success" label="إضيف المستشار وأرسل إليه بريد إلكتروني لتفعيل حسابه" />
          <LCButton
            label="إغلاق"
            type="button"
            buttonStyle="roundedStyle1Outlined"
            className="float-left"
            onClick={() => setVisible(false)}
          />
        </>
      )}
    </Dialog>
  );
};

export default CreateConsultantUser;
