import { createContext } from 'react';

interface AdminAndSupportLayoutContextValue {
  title: string;
  titleIcon: JSX.Element;
  titleAction: JSX.Element;
  breadcrumbsTitle: string;
  breadcrumbs: AdminAndSupportLayoutContextBreadcrumbs[];
  setTitle?: (title: string) => void;
  setTitleIcon?: (titleIcon: JSX.Element) => void;
  setTitleAction?: (titleAction: JSX.Element) => void;
  setBreadcrumbsTitle?: (breadcrumbsTitle: string) => void;
  setBreadcrumbs?: (breadcrumbs: AdminAndSupportLayoutContextBreadcrumbs[]) => void;
  setAll?: (newValues: AdminAndSupportLayoutContextUpdateParams) => void;
}

export interface AdminAndSupportLayoutContextBreadcrumbs {
  label: string;
  icon?: JSX.Element;
  linkTo?: string;
}

export interface AdminAndSupportLayoutContextUpdateParams {
  title?: string;
  titleIcon?: JSX.Element;
  titleAction?: JSX.Element;
  breadcrumbsTitle?: string;
  breadcrumbs?: AdminAndSupportLayoutContextBreadcrumbs[];
}

const adminAndSupportLayoutContextInitialState: AdminAndSupportLayoutContextValue = {
  title: '',
  titleIcon: null,
  titleAction: null,
  breadcrumbsTitle: '',
  breadcrumbs: null,
};

export const AdminAndSupportLayoutContext = createContext(adminAndSupportLayoutContextInitialState);
