import './Button.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styleBtn?: string;
  handleClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

const Button = ({ styleBtn, handleClick, children, disabled, type }: ButtonProps) => {
  const btnClasses = `btn ${styleBtn ? styleBtn : ''}`;
  return (
    <button className={btnClasses} disabled={disabled} onClick={handleClick} type={type || 'button'}>
      {children}
    </button>
  );
};

export default Button;
