import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useRef, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import { QuestionsApi } from '../../api/questionsApi';
import icon_book from '../../assets/icons/icon_book.svg';
import ApproveQuestionPopup, { ApproveQuestionPopupAttributes } from '../../components/ApproveQuestionPopup';
import ConsultantContentCard from '../../components/ConsultantContentCard';
import LCButton from '../../components/form/LCButton';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCSimpleMessage from '../../components/LCMessage';
import ReassignQuestionPopup, { ReassignQuestionPopupAttributes } from '../../components/ReassignQuestionPopup';
import UserContentCard from '../../components/UserContentCard';
import { UserContext } from '../../contexts/UserContext';
import {
  AnswerStatuses,
  GetQuestionResponse,
  GetQuestionResponseAnswer,
  GetQuestionResponseReview,
  QuestionStatuses,
  ReviewStatuses,
} from '../../models/questionsApi';
import { UserTypes, Permissions } from '../../models/user';
import { appendQuestionPrefix } from '../../utility/stringUtility';
import { ReassignQuestionDialogAttributes } from '../questions/ReassignQuestionDialog';
import RejectQuestionDialog, { RejectQuestionDialogAttributes } from '../questionsForAnswer/RejectQuestionDialog';
import EditAnswerDialogReview, { EditAnswerDialogAttributes } from './EditAnswerReview';
import './questionsForReview.css';
import ReturnQuestionDialog from './ReturnQuestionDialog';

const QuestionsForReviewDetails = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState<GetQuestionResponse>(null);
  const [answer, setAnswer] = useState<GetQuestionResponseAnswer>(null);
  const [review, setReview] = useState<GetQuestionResponseReview>(null);
  const [submitted, setSubmitted] = useState(false);
  const [submitReason, setSubmitReason] = useState<'approve' | 'review' | 'reassign'>(null);
  const [approvingQuestion, setApprovingQuestion] = useState(false);
  const [reassigningQuestion, setReassigningQuestion] = useState(false);
  const [returnQuestionVisible, setReturnQuestionVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isChangedByJudge, setChangedByJudge] = useState(false);

  const userContext = useContext(UserContext);
  const reassignDialogRef = useRef<ReassignQuestionDialogAttributes>(null);
  const editAnswerDialogRef = useRef<EditAnswerDialogAttributes>(null);
  const rejectQuestionDialogRef = useRef<RejectQuestionDialogAttributes>(null);

  const approveQuestionPopupRef = useRef<ApproveQuestionPopupAttributes>(null);
  const reassignQuestionPopupRef = useRef<ReassignQuestionPopupAttributes>(null);

  useEffect(() => {
    QuestionsApi.getQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        const q = res.data.data;
        setQuestion(q);
        setAnswer(q.answers[0]);
        setReview(q.reviews[0]);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const loadQuestion = () => {
    setLoading(true);
    QuestionsApi.getQuestion({ id: Number.parseInt(id) }).then(
      (res) => {
        const q = res.data.data;
        //setQuestion(q);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const shouldShowEditAnswerButton = () => {
    return userContext.isUserType([UserTypes.CONSULTANT]);
  };
  const questionEditable = () => {
    return (
      (review?.status === ReviewStatuses.ASSIGNED_TO_JUDGE || review.status === ReviewStatuses.REASSIGNED) &&
      question.status !== QuestionStatuses.REJECTED
    );
  };
  const questionCanBeReassigned = () => {
    return answer?.status === AnswerStatuses.ASSIGNED_TO_CONSULTANT || QuestionStatuses.ASSIGNED_TO_JUDGE;
  };
  const shouldShowRejectButton = () => {
    return questionEditable() && answer.status !== AnswerStatuses.RETURNED_TO_CONSULTANT;
  };
  return (
    <div className="grow px-4 lg:px-16 py-8">
      <div className="q-f-r-header">
        <p className="text-adminBreadcrumbs font-bold text-2xl">عرض الاستشارة</p>
        <div className="bg-adminBreadcrumbs w-1 self-stretch"></div>

        <div className="flex gap-1 items-center">
          <div className="flex items-center">
            <img src={icon_book} alt="Icon" className="inline ml-2 h-5" />
            <Link to="/c" className="text-xl">
              قائمة الاستشارات
            </Link>
          </div>

          <HiOutlineChevronRight className="text-lg" />

          <Link to="/c" className="text-xl">
            استشارات تحكيم
          </Link>

          <HiOutlineChevronRight className="text-lg" />

          <p className="text-xl">عرض الاستشارة</p>
        </div>
      </div>

      {loading && <ProgressSpinner />}
      {!loading && !submitted && (
        <div className="flex flex-col gap-5">
          <h1 className="q-f-r-title">{question.title}</h1>

          <UserContentCard
            date={question.createdAt}
            content={appendQuestionPrefix(question.content)}
            backgroundColorClass="bg-[#f9f9f9]"
          />

          <ConsultantContentCard
            consultantName={answer.user}
            contentDate={question.answeredAt}
            type="answer"
            status={answer.status}
            content={answer.adminContent || answer.content}
            isContentChanged={!!answer.adminContent}
            rejectionReason={question.rejectionReason}
            backgroundColorClass="bg-[#f9f9f9]"
          />

          {!questionEditable() && (
            <ConsultantContentCard
              consultantName={review.user}
              contentDate={question.reviewedAt}
              type="review"
              status={AnswerStatuses.REJECTED ? answer.status : review.status}
              content={review.judgeAnswer}
              backgroundColorClass="bg-[#f9f9f9]"
            />
          )}

          {questionEditable() && (
            <div className="flex justify-center gap-5">
              <LCButton
                label="اعتماد الإجابة"
                type="button"
                onClick={(e) => approveQuestionPopupRef.current?.show(e)}
                disabled={approvingQuestion}
                loading={approvingQuestion}
              />
              {/* <LCButton label="مراجعة الإجابة" type="button" onClick={() => setReturnQuestionVisible(true)} /> */}
              <LCButton
                label="تعديل الإجابة"
                type="button"
                onClick={() => {
                  setEditMode(true);
                  setChangedByJudge(false);
                  editAnswerDialogRef.current.show();
                }}
              />
              <LCButton
                label="تغيير الإجابة"
                type="button"
                onClick={() => {
                  setEditMode(false);
                  setChangedByJudge(true);
                  editAnswerDialogRef.current.show();
                }}
              />
              {questionCanBeReassigned() && (
                <LCButton
                  label="إعادة إسناد"
                  type="button"
                  onClick={(e) => reassignQuestionPopupRef.current?.show(e)}
                  disabled={reassigningQuestion}
                  loading={reassigningQuestion}
                />
              )}
              {shouldShowRejectButton() && (
                <LCButton
                  label="الاعتذار عن الإجابة"
                  type="button"
                  onClick={() => rejectQuestionDialogRef.current.show()}
                />
              )}
            </div>
          )}

          <ApproveQuestionPopup
            ref={approveQuestionPopupRef}
            id={Number.parseInt(id)}
            setLoading={setApprovingQuestion}
            successCallback={() => {
              setSubmitReason('reassign');
              setSubmitted(true);
            }}
          />

          <RejectQuestionDialog
            ref={rejectQuestionDialogRef}
            id={Number.parseInt(id)}
            isJudge={true}
            questionRejectedCallback={() => {
              //setSubmitReason('reject');
              setSubmitted(true);
            }}
          />
          <ReturnQuestionDialog
            id={Number.parseInt(id)}
            visible={returnQuestionVisible}
            setVisible={setReturnQuestionVisible}
            questionReturnedCallback={() => {
              setSubmitReason('review');
              setSubmitted(true);
            }}
          />
          {shouldShowEditAnswerButton() && (
            <>
              {/* ================================================= */}
              {/* ================================================= */}
              {/* ================================================= */}
              {/* ================================================= */}
              {/* ================================================= */}
              {/* ================================================= */}
              <EditAnswerDialogReview
                editAnswer={editMode}
                ref={editAnswerDialogRef}
                isChangedByJudge={isChangedByJudge}
                answerId={question.answers[0].id}
                originalContent={question.answers[0].content}
                questionContent={question}
                adminContent={question.answers[0].adminContent}
                answerEditedCallback={() => {
                  setSubmitReason('review');
                  setSubmitted(true);
                }}
                questionTitle={question.title}
              />
            </>
          )}
          <ReassignQuestionPopup
            ref={reassignQuestionPopupRef}
            id={Number.parseInt(id)}
            type="review"
            setLoading={setReassigningQuestion}
            successCallback={() => {
              setSubmitReason('reassign');
              setSubmitted(true);
            }}
          />
        </div>
      )}
      {submitted && (
        <>
          {
            {
              approve: <LCSimpleMessage type="success" label="اعتمدت الاستشارة"></LCSimpleMessage>,
              review: <LCSimpleMessage type="success" label="أرسلت المراجعة"></LCSimpleMessage>,
              reassign: <LCSimpleMessage type="success" label="أعيد إسناد الاستشارة بنجاح"></LCSimpleMessage>,
            }[submitReason]
          }
          <LCLinkButton to="/c" label="عودة إلى قائمة استشارات التحكيم" className="inline-block mt-3" />
        </>
      )}
    </div>
  );
};

export default QuestionsForReviewDetails;
