import React, { createContext, useReducer } from 'react';
import { Action } from '../models/action';
import { Permission, UserTypes } from '../models/user';
import { User, UserContextValue, USER_LOCAL_STORAGE_KEY } from '../models/userContext';
import { UserContextProxy } from '../models/userContextProxy';

const initialState: UserContextValue = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  //authToken: null,
  userType: {
    name: null,
  },
  permissions: [],
  receiveQuestionsEnabled: null,
  position: null,
};

const actions = {
  SET_USER: 'SET_USER',
  RESET_USER: 'RESET_USER',
};

const initialStateWithUser = (): UserContextValue => {
  return JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY)) || initialState;
};

const reducer = (state: User, action: Action) => {
  switch (action.type) {
    case actions.SET_USER:
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(action.payload));
      return action.payload;

    case actions.RESET_USER:
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      return initialState;

    default:
      return state;
  }
};

export const UserContext = createContext(initialStateWithUser());

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialStateWithUser());

  const value: UserContextValue = {
    id: state.id,
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    //authToken?: state.authToken,
    userType: state.userType,
    permissions: state.permissions,
    receiveQuestionsEnabled: state.receiveQuestionsEnabled,
    position: state.position,
    setUser: (user: User) => {
      dispatch({ type: actions.SET_USER, payload: user });
    },
    resetUser: () => {
      dispatch({ type: actions.RESET_USER });
    },
    isLoggedIn: () => state.id != null,
    isUserType: (userType: UserTypes | UserTypes[]) =>
      (Array.isArray(userType) && userType.some((type) => type === state.userType.name)) ||
      userType === state.userType.name,
    hasPermission: (permissionName: string) =>
      state.permissions
        ?.filter((p: Permission) => p.UserPermission.isEnabled)
        .some((p: Permission) => p.name === permissionName),
  };

  Object.assign(UserContextProxy, value);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
