import { Column } from 'primereact/column';
import { DataTable, DataTablePFSEvent } from 'primereact/datatable';
import { useContext, useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { QuestionsApi } from '../../api/questionsApi';
import { UsersApi } from '../../api/usersApi';
import icon_user_paper from '../../assets/icons/icon_user_paper.svg';
import LCButton from '../../components/form/LCButton';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCSearch from '../../components/form/LCSearch';
import { AdminAndSupportLayoutContext } from '../../contexts/AdminAndSupportLayoutContext';
import { UserContext } from '../../contexts/UserContext';
import { PAGINATION_LIMIT, paginatorTemplate } from '../../models/pagination';
import { Permission, Permissions } from '../../models/user';
import { ListConsultantUsersRequest, ListConsultantUsersResponseItem } from '../../models/usersApi';
import { getFullName } from '../../utility/stringUtility';
import CreateConsultantUser from './CreateConsultantUser';
import './CreateConsultantUser.css';
import { saveAs } from 'file-saver';
import { CgMathPlus } from 'react-icons/cg';
import { convertNumsToArabic } from '../../helpers/number_converter';
const ConsultantUsersList = () => {
  const userContext = useContext(UserContext);
  const layoutContext = useContext(AdminAndSupportLayoutContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ListConsultantUsersResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListConsultantUsersRequest>({
    searchParam: '',
    offset: 0,
    limit: PAGINATION_LIMIT,
  });
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  useEffect(() => {
    updateLayout();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [request]);

  const loadUsers = () => {
    setLoading(true);
    UsersApi.listConsultantUsers(request).then(
      (res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  const DownloadExcelSheet = async () => {
    await UsersApi.downloadConsultantUsersReport().then(
      (res) => {
        saveAs(res.data, 'المستشارين.xlsx');
        //setSubmitting(false);
      },
      () => {
        //setSubmitting(false);
      }
    );
  };
  const updateLayout = () => {
    layoutContext.setAll({ title: 'إدارة المستشارين', titleIcon: <img src={icon_user_paper} alt="Icon" /> });
  };

  const mapIsEnabled = (isEnabled: boolean) => {
    if (isEnabled) return 'مفعل';
    else return 'معطل';
  };

  const mapType = (permissions: Permission[]) => {
    if (permissions.find((p) => p.name === Permissions.JUDGE_QUESTIONS && p.UserPermission.isEnabled)) {
      return 'مستشار محكم';
    } else {
      return 'مستشار';
    }
  };

  const handleOnPage = (event: DataTablePFSEvent) => {
    setRequest({ ...request, offset: event.first, limit: event.rows });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-center items-center lg:justify-between flex-col lg:flex-row gap-4">
        <LCSearch loading={loading} onSubmit={(searchParam) => setRequest({ ...request, searchParam, offset: 0 })} />
        <div className="flex flex-row justify-around w-1/4">
          <LCButton
            className="max-w-[140px]"
            label="تحميل"
            icon={<FaFileExcel className="ml-2 " />}
            buttonStyle="roundedStyle2"
            onClick={() => DownloadExcelSheet()}
          />
          {userContext.hasPermission(Permissions.CREATE_CONSULTANTS) && (
            <>
              <LCButton
                className="max-w-[140px]"
                label="إضافة"
                icon={<CgMathPlus className="ml-2 " />}
                buttonStyle="roundedStyle2"
                onClick={() => setCreateDialogVisible(true)}
              />
              <CreateConsultantUser
                visible={createDialogVisible}
                setVisible={setCreateDialogVisible}
                userCreatedCallback={loadUsers}
              />
            </>
          )}
        </div>
      </div>

      <DataTable
        value={users}
        loading={loading}
        scrollable
        scrollDirection="horizontal"
        className="lc-table"
        lazy
        paginator
        paginatorTemplate={paginatorTemplate}
        first={request.offset}
        rows={PAGINATION_LIMIT}
        totalRecords={total}
        onPage={handleOnPage}>
        <Column header="المعرف" body={(rowData) => convertNumsToArabic(rowData.id)} />
        <Column
          header="الاسم"
          body={(rowData) => getFullName(rowData)}
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
        <Column
          header="الحالة"
          body={(rowData) => (
            <div className={`${rowData.isEnabled ? `bg-certified` : `bg-rejected`}  rounded-md p-2 text-white`}>
              {mapIsEnabled(rowData.isEnabled)}
            </div>
          )}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="عدد الاستشارات المسندة المعلقة"
          field="numberOfPendingAssignedQuestions"
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="النوع"
          body={(rowData) => mapType(rowData.permissions)}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="استقبال الاستشارات"
          body={(rowData) => mapIsEnabled(rowData.receiveQuestionsEnabled)}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
        <Column
          header="التفاصيل"
          body={(rowData) => <LCLinkButton label="عرض" to={rowData.id.toString()} buttonStyle="rounded" />}
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
          }}
        />
      </DataTable>
    </div>
  );
};

export default ConsultantUsersList;
