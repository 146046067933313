import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { UsersApi } from '../../api/usersApi';
import LCButton from '../../components/form/LCButton';
import PermissionsSelector from '../../components/PermissionsSelector';
import { ToastManager } from '../../components/toast/toastManager';
import './EditTechnicalSupportUser.css';

interface EditTechnicalSupportUserProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userId: number;
  permissions: { id: number; label: string; selected: boolean }[];
  userUpdatedCallback: (enabledPermissionIds: number[]) => void;
}

const EditTechnicalSupportUser = ({
  visible,
  setVisible,
  userId,
  permissions,
  userUpdatedCallback,
}: EditTechnicalSupportUserProps) => {
  const [enabledPermissionIds, setEnabledPermissionIds] = useState<number[]>([]);
  const [disabledPermissionIds, setDisabledPermissionIds] = useState<number[]>([]);
  const [savingPermissions, setSavingPermissions] = useState(false);

  const savePermissions = () => {
    setSavingPermissions(true);

    UsersApi.updatePermissions({
      id: userId,
      enable: enabledPermissionIds,
      disable: disabledPermissionIds,
    }).then(
      () => {
        userUpdatedCallback(enabledPermissionIds);
        setSavingPermissions(false);
        ToastManager.showSuccess('تم تعديل الصلاحيات بنجاح');
        setVisible(false);
      },
      () => {
        setSavingPermissions(false);
      }
    );
  };

  return (
    <Dialog
      header={
        <div className="flex w-3/5 gap-2">
          <h1 className="text-3xl font-bold">تعديل صلاحيات المستخدم</h1>
          <div className="h-1 grow bg-black relative self-end bottom-3"></div>
        </div>
      }
      closable={false}
      className="m-5 md:w-1/2 font-main"
      contentClassName="text-lg"
      resizable={false}
      draggable={false}
      dismissableMask={true}
      visible={visible}
      onHide={() => setVisible(false)}>
      <div className="flex flex-col gap-6">
        <PermissionsSelector
          permissions={permissions}
          onPermissionsChange={(enabledPermissionIds, disabledPermissionIds) => {
            setEnabledPermissionIds(enabledPermissionIds);
            setDisabledPermissionIds(disabledPermissionIds);
          }}
        />

        <div className="flex flex-row-reverse gap-2">
          <LCButton label="إلغاء" type="button" buttonStyle="roundedStyle1Outlined" onClick={() => setVisible(false)} />
          <LCButton
            label="حفظ"
            type="button"
            buttonStyle="roundedStyle1"
            onClick={savePermissions}
            disabled={savingPermissions}
            loading={savingPermissions}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default EditTechnicalSupportUser;
