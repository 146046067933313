import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { UsersApi } from '../api/usersApi';
import { UserContext } from '../contexts/UserContext';
import { lcConfirmPopup } from '../utility/lcConfirmPopup';
import LCButton from './form/LCButton';

interface EnableDisableOwnReceivingQuestionsProps {
  className?: string;
}

const EnableDisableOwnReceivingQuestions = ({ className }: EnableDisableOwnReceivingQuestionsProps) => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const setUserReceiveQuestionsEnabled = (receiveQuestionsEnabled: boolean) => {
    userContext.setUser({ ...userContext, receiveQuestionsEnabled });
  };

  const enableReceivingQuestions = (event: React.MouseEvent<HTMLButtonElement>) => {
    lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من تفعيل استقبال الاستشارات؟', () => {
      setLoading(true);
      UsersApi.enableReceivingQuestions({ id: userContext.id }).then(
        () => {
          setUserReceiveQuestionsEnabled(true);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    });
  };

  const disableReceivingQuestions = (event: React.MouseEvent<HTMLButtonElement>) => {
    lcConfirmPopup(event.currentTarget, 'هل أنت متأكد من تعطيل استقبال الاستشارات؟', () => {
      setLoading(true);
      UsersApi.disableReceivingQuestions({ id: userContext.id }).then(
        () => {
          setUserReceiveQuestionsEnabled(false);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    });
  };

  return (
    <>
      {userContext.receiveQuestionsEnabled && (
        <LCButton
          label="استقبال الاستشارات مفعل"
          buttonStyle="noStyle"
          className={`bg-[#1CAA64] text-white border-none rounded-lg px-10 py-0 shadow-none font-main text-lg ${className}`}
          onClick={disableReceivingQuestions}
          disabled={loading}
          loading={loading}
          icon={<FaCheck className="inline ml-3" />}
          type="button"
        />
      )}
      {!userContext.receiveQuestionsEnabled && (
        <LCButton
          label="استقبال الاستشارات معطل"
          buttonStyle="noStyle"
          className={`bg-[#474747] text-white border-none rounded-lg px-10 py-0 shadow-none font-main text-lg ${className}`}
          onClick={enableReceivingQuestions}
          disabled={loading}
          loading={loading}
          icon={<FaTimes className="inline ml-3" />}
          type="button"
        />
      )}
    </>
  );
};

EnableDisableOwnReceivingQuestions.propTypes = {
  receivingQuestionsEnabled: PropTypes.bool,
  setReceivingQuestionsEnabled: PropTypes.func,
};

export default EnableDisableOwnReceivingQuestions;
