import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import preParsePostFormat from 'dayjs/plugin/preParsePostFormat'
dayjs.extend(advancedFormat)
dayjs.extend(preParsePostFormat)


export const getFullName = (object: { firstName: string; lastName: string }) => {
  return `${object.firstName} ${object.lastName}`;
};

export const getFormattedDate = (date: string) => {
  if (date) return dayjs(date).locale('ar').format('D MMM YYYY')
  else return '-';
};

export const getFormattedTime = (date: string) => {
  if (date) return dayjs(date).locale('ar').format('h:mm a');
  else return '-';
};

export const getFormattedFullDate = (date: string) => {
  if (date) return dayjs(date).locale('ar').format('D.MMM.YYYY h:mm a');
  else return '-';
};

export const appendQuestionPrefix = (question: string) => `السؤال: ${question}`;
export const appendAnswerPrefix = (answer: string) => `الجواب: ${answer}`;
export const appendConsultantPrefix = (object: { firstName: string; lastName: string }) => {
  return `المستشار: ${getFullName(object)}`;
};
