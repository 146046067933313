import {
  ActivateUserRequest,
  ActivateUserResponse,
  LoginRequest,
  LoginResponse,
  RegisterRequest,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  VerifyOtpRequest,
  VerifyOtpResponse,
} from '../models/authApi';
import { BackendResponse } from '../models/backendResponse';
import { Axios } from './axios';

const baseUrl = '/auth';

const register = (data: RegisterRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/register`, data);
};

const verifyOtp = (data: VerifyOtpRequest) => {
  return Axios.post<BackendResponse<VerifyOtpResponse>>(`${baseUrl}/verify_otp`, data);
};

const login = (data: LoginRequest) => {
  return Axios.post<BackendResponse<LoginResponse>>(`${baseUrl}/login`, data);
};

const logout = () => {
  return Axios.get(`${baseUrl}/logout`);
};

const adminLogin = (data: LoginRequest) => {
  return Axios.post<BackendResponse<LoginResponse>>(`${baseUrl}/adminLogin`, data);
};

const requestPasswordReset = (data: RequestPasswordResetRequest) => {
  return Axios.post<BackendResponse<{}>>(`${baseUrl}/reset_password_email`, data);
};

const resetPassword = (data: ResetPasswordRequest) => {
  return Axios.post<BackendResponse<ResetPasswordResponse>>(`${baseUrl}/reset_password`, data);
};

const activateUser = (data: ActivateUserRequest) => {
  return Axios.post<BackendResponse<ActivateUserResponse>>(`${baseUrl}/activate`, data);
};

export const AuthApi = {
  adminLogin,
  register,
  verifyOtp,
  login,
  requestPasswordReset,
  resetPassword,
  activateUser,
  logout,
};
