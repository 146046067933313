import { QuestionStatuses } from '../models/questionsApi';

const questionStatusMap = {
  NEW: { name: 'جديدة', color: "#0E6655" },
  ASSIGNED_TO_CONSULTANT: { name: 'مسندة إلى مستشار', color: "#0E6655" },
  ANSWERED: { name: 'مجابة', color: "#2dccff" },
  REJECTED: { name: 'مرفوضة', color: "#ff3838" },
  REASSIGNED: { name: 'معاد إسنادها', color: "#E59866" },
  ASSIGNED_TO_JUDGE: { name: 'مسندة إلى محكم', color: "#ff9e22" },
  CERTIFIED: { name: 'معتمدة', color: "#56f000" },
  RETURNED_TO_CONSULTANT: { name: 'معادة إلى المستشار', color: "#F7DC6F" },
};

export const questionStatusMapper = (questionStatus: QuestionStatuses) => questionStatusMap[questionStatus];
