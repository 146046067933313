import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AuthApi } from '../api/authApi';
import icon_email from '../assets/icons/icon_email.svg';
import login_image from '../assets/images/login_image.png';
import Button from '../components/button/Button';
import LinkBtn from '../components/button/LinkBtn';
import LcInput from '../components/Input/LcInput';
import LCSimpleMessage from '../components/LCMessage';
import { LoginLayoutContext } from '../contexts/LoginLayoutContext';
import ReCAPTCHA from 'react-google-recaptcha';

const RequestPasswordReset = () => {
  const loginLayoutContext = useContext(LoginLayoutContext);
  const [submitted, setSubmitted] = useState(false);
  const [capVal, setCapVal] = React.useState(null);

  useEffect(() => {
    loginLayoutContext.setTitle('إعادة تعيين كلمة المرور');
    loginLayoutContext.setImage(login_image);
  }, []);

  return (
    <>
      {!submitted ? (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            AuthApi.requestPasswordReset(values).then(
              () => {
                setSubmitting(false);
                setSubmitted(true);
              },
              () => {
                setSubmitting(false);
              }
            );
          }}>
          {({ isSubmitting }) => (
            <Form className="flex flex-col items-start gap-4 w-fil lg:pl-24">
              <LcInput
                id="email"
                name="email"
                label="البريد الإلكتروني"
                type="email"
                className="w-full"
                icon={icon_email}
              />
              <ReCAPTCHA
                sitekey="6LfyvAkqAAAAAE_AcTYiG1FBQVpf6a1qKyx7fZ9H"
                onChange={(val: any) => {
                  setCapVal(val);
                }}
              />
              <Button type="submit" disabled={capVal === null || isSubmitting}>
                استعادة كلمة المرور
              </Button>

              <Link to="/login" className="text-primary font-main">
                عودة إلى تسجيل الدخول
              </Link>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="flex flex-col items-start gap-4 w-fil lg:pl-24">
          <LCSimpleMessage type="success" label="لقد أرسل بريد إلكتروني برابط إعادة تعيين كلمة المرور" />
          <LinkBtn href="/login">عودة إلى تسجيل الدخول</LinkBtn>
        </div>
      )}
    </>
  );
};

export default RequestPasswordReset;
