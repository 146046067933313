import dayjs from 'dayjs';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { CgMathPlus } from 'react-icons/cg';
import { MdShare } from 'react-icons/md';
import { QuestionsApi } from '../../api/questionsApi';
import icon_chat from '../../assets/icons/icon_chat.svg';
import avatar_1 from '../../assets/images/avatar_1.png';
import LCButton from '../../components/form/LCButton';
import LCLinkButton from '../../components/form/LCLinkButton';
import ShareQuestionWrapper from '../../components/ShareQuestionWrapper';

import { PAGINATION_LIMIT } from '../../models/pagination';
import { ListMyQuestionsRequest, ListMyQuestionsResponseItem, QuestionStatuses } from '../../models/questionsApi';
import { getFormattedDate } from '../../utility/stringUtility';
import './MyQuestion.css';

const MyQuestionList = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<ListMyQuestionsResponseItem[]>([]);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState<ListMyQuestionsRequest>({
    offset: 0,
    limit: PAGINATION_LIMIT,
  });

  useEffect(() => {
    loadQuestions();
  }, [request]);

  const loadQuestions = () => {
    setLoading(true);
    QuestionsApi.listMyQuestions(request).then(
      (res) => {
        setQuestions(questions.concat(res.data.data));
        setTotal(res.data.total);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleOnLoadMore = () => {
    setRequest({ ...request, offset: request.offset + request.limit });
  };

  const shouldShowLoadMoreButton = () => questions.length < total;

  // const getFormattedDate = (date: string) => {
  //   return dayjs(date).format('D.MMM.YYYY');
  // };

  const shouldShowShareButton = (question: ListMyQuestionsResponseItem) => {
    return question.status === QuestionStatuses.CERTIFIED && !question.isHidden;
  };

  return (
    <div className="my-q-l">
      <div className="my-q-l-header-container">
        <div className="my-q-l-header">
          <img src={icon_chat} alt="Icon" className="h-10" />
          <h1 className="my-q-l-title">استشاراتي</h1>
        </div>
        <LCLinkButton to="/new-question" label="اطلب مشورة" buttonStyle="rounded" icon={<CgMathPlus />} />
      </div>

      {loading && <ProgressSpinner />}

      {questions?.map((question) => (
        <div key={question?.id} className="my-q-l-content">
          <div className="my-q-l-body">
            <div className="flex items-start gap-3">
              <img src={avatar_1} alt="User avatar" className="w-10 h-10" />
              <div className="mt-1">
                <p className="text-xl font-bold">{question?.title}</p>
                <p className="text-right" style={{ direction: 'ltr' }}>
                  {getFormattedDate(question?.createdAt)}
                </p>
              </div>
            </div>

            {shouldShowShareButton(question) && (
              <ShareQuestionWrapper questionId={question?.id}>
                <button className="bg-[#eaefec] rounded-lg p-2 mt-2 ml-2">
                  <MdShare />
                </button>
              </ShareQuestionWrapper>
            )}
          </div>

          <div className="flex my-2">
            <div className="my-q-l-p"></div>
            <p className="px-4 py-3 text-2xl">{question?.content}</p>
          </div>

          <LCLinkButton label="عرض الاستشارة" to={question?.id?.toString()} className="my-q-l-btn" />
        </div>
      ))}

      {shouldShowLoadMoreButton() && (
        <LCButton
          type="button"
          label="عرض المزيد"
          buttonStyle="roundedStyle1"
          className="self-center mt-5 md:px-20"
          onClick={handleOnLoadMore}
          loading={loading}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default MyQuestionList;
