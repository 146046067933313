import { Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { UsersApi } from '../../api/usersApi';
import { UserTypesApi } from '../../api/userTypesApi';
import icon_email_outlined from '../../assets/icons/icon_email_outlined.svg';
import icon_id_card from '../../assets/icons/icon_id_card.svg';
import icon_star_card from '../../assets/icons/icon_star_card.svg';
import LCButton from '../../components/form/LCButton';
import LCInputTextRounded from '../../components/form/LCInputTextRounded';
import LCSimpleMessage from '../../components/LCMessage';
import PermissionsSelector from '../../components/PermissionsSelector';
import { UserTypes } from '../../models/user';
import { ListUserTypesWithPermissionsPermission } from '../../models/userTypesApi';
import { getPermissionsForUserType } from '../../utility/permissionsUtility';

interface CreateTechnicalSupportUserProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userCreatedCallback: () => void;
}

const CreateTechnicalSupportUser = ({ visible, setVisible, userCreatedCallback }: CreateTechnicalSupportUserProps) => {
  const [userAdded, setUserAdded] = useState(false);
  const [permissionsLoading, setPermissionsLoading] = useState(false);
  const [permissionsLoadingError, setPermissionsLoadingError] = useState(false);
  const [permissionsList, setPermissionsList] = useState<ListUserTypesWithPermissionsPermission[]>(null);

  useEffect(() => {
    setUserAdded(false);

    if (!visible || permissionsList || permissionsLoading) return;

    setPermissionsLoading(true);
    setPermissionsLoadingError(false);
    UserTypesApi.listUserTypesWithPermissions().then(
      (res) => {
        const userTypesWithPermissions = res.data.data;
        const permissions = getPermissionsForUserType(userTypesWithPermissions, UserTypes.SUPPORT);
        setPermissionsList(permissions);
        setPermissionsLoading(false);
      },
      () => {
        setPermissionsLoadingError(true);
        setPermissionsLoading(false);
      }
    );
  }, [visible]);

  return (
    <Dialog
      header={
        <div className="flex w-3/5 gap-2">
          <h1 className="text-3xl font-bold">إضافة مستخدم جديد</h1>
          <div className="h-1 grow bg-black relative self-end bottom-3"></div>
        </div>
      }
      closable={false}
      className="m-5 md:w-1/2 font-main"
      contentClassName="text-lg"
      resizable={false}
      draggable={false}
      dismissableMask={true}
      visible={visible}
      onHide={() => setVisible(false)}>
      {!userAdded ? (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            enabledPermissionIds: [],
            disabledPermissionIds: [],
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            lastName: Yup.string().max(40, 'الحد الأقصى أربعون حرفا').required('إلزامي'),
            email: Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي'),
            enabledPermissionIds: Yup.array(),
            disabledPermissionIds: Yup.array(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            UsersApi.createTechnicalSupportUser({
              ...values,
              enable: values.enabledPermissionIds,
              disable: values.disabledPermissionIds,
            }).then(
              () => {
                setSubmitting(false);
                setUserAdded(true);
                userCreatedCallback();
              },
              () => {
                setSubmitting(false);
              }
            );
          }}>
          {({ isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col gap-6">
              <p>
                عند إضافة مستخدم جديد سيريل رابط على البريد الإلكتروني لتفعيل الحساب ثم يقوم بنقله لينشئ كلمة المرور
                الخاصة به
              </p>

              <div className="grid grid-cols-2-form gap-x-2 gap-y-4">
                <div className="flex items-center gap-2 text-xl">
                  <img src={icon_id_card} alt="Icon" className="h-6" />
                  <label htmlFor="firstName">الاسم الأول</label>
                </div>
                <LCInputTextRounded
                  id="firstName"
                  name="firstName"
                  type="text"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2 text-xl">
                  <img src={icon_id_card} alt="Icon" className="h-6" />
                  <label htmlFor="lastName">الاسم الأخير</label>
                </div>
                <LCInputTextRounded
                  id="lastName"
                  name="lastName"
                  type="text"
                  inputClassName="w-full"
                  containerClassName="grow"
                />

                <div className="flex items-center gap-2 text-xl">
                  <img src={icon_email_outlined} alt="Icon" className="h-6" />
                  <label htmlFor="email">البريد الإلكتروني</label>
                </div>
                <LCInputTextRounded
                  id="email"
                  name="email"
                  type="email"
                  inputClassName="w-full"
                  containerClassName="grow"
                />
              </div>

              <div className="flex items-start gap-2 mt-4 text-xl">
                <img src={icon_star_card} alt="Icon" className="h-6" />
                <div>الصلاحيات</div>
                {permissionsLoading && (
                  <div className="w-1/2 space-y-3">
                    <Skeleton className="h-6" />
                    <Skeleton className="h-6" />
                    <Skeleton className="h-6" />
                    <Skeleton className="h-6" />
                    <Skeleton className="h-6" />
                  </div>
                )}
                {permissionsLoadingError && <p>حصل خطأ أثناء تحميل الصلاحيات، يرجى المحاولة مرة أخرى لاحقًا</p>}
                {permissionsList && (
                  <PermissionsSelector
                    permissions={permissionsList.map((p) => ({ id: p.id, label: p.arName, selected: false }))}
                    onPermissionsChange={(enabledPermissionIds, disabledPermissionIds) => {
                      setFieldValue('enabledPermissionIds', enabledPermissionIds);
                      setFieldValue('disabledPermissionIds', disabledPermissionIds);
                    }}
                  />
                )}
              </div>

              <div className="flex flex-row-reverse gap-2">
                <LCButton
                  label="إلغاء"
                  type="button"
                  buttonStyle="roundedStyle1Outlined"
                  onClick={() => setVisible(false)}
                />
                <LCButton
                  label="إضافة"
                  type="submit"
                  buttonStyle="roundedStyle1"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <LCSimpleMessage type="success" label="أضيف المستخدم وأرسل بريد إلكتروني إليه لتفعيل حسابه" />
          <LCButton
            label="إغلاق"
            type="button"
            buttonStyle="roundedStyle1Outlined"
            className="float-left"
            onClick={() => setVisible(false)}
          />
        </>
      )}
    </Dialog>
  );
};

export default CreateTechnicalSupportUser;
