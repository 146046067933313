import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LCCheckbox from './form/LCCheckbox';

interface PermissionsSelectorProps {
  permissions: { id: number; label: string; selected: boolean }[];
  onPermissionsChange: (enabledPermissionIds: number[], disabledPermissionIds: number[]) => void;
}

const PermissionsSelector = ({ permissions, onPermissionsChange }: PermissionsSelectorProps) => {
  const [enabledPermissionIds, setEnabledPermissionIds] = useState<number[]>(
    permissions.filter((p) => p.selected).map((p) => p.id)
  );
  const [disabledPermissionIds, setDisabledPermissionIds] = useState<number[]>(
    permissions.filter((p) => !p.selected).map((p) => p.id)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permissionId = Number.parseInt(event.target.value);
    const checked = event.target.checked;
    if (checked) {
      setEnabledPermissionIds([...enabledPermissionIds, permissionId]);
      setDisabledPermissionIds(disabledPermissionIds.filter((p) => p !== permissionId));
    } else {
      setEnabledPermissionIds(enabledPermissionIds.filter((p) => p !== permissionId));
      setDisabledPermissionIds([...disabledPermissionIds, permissionId]);
    }
  };

  useEffect(() => {
    onPermissionsChange(enabledPermissionIds, disabledPermissionIds);
  }, [enabledPermissionIds, disabledPermissionIds]);

  return (
    <div role="group">
      {permissions.map((permission) => (
        <LCCheckbox
          id={`permission-${permission.id}`}
          key={permission.id}
          label={permission.label}
          value={permission.id}
          checked={enabledPermissionIds.includes(permission.id)}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

PermissionsSelector.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.object),
  onPermissionsChange: PropTypes.func,
};

export default PermissionsSelector;
