import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { AnswerApi } from '../../api/answerApi';
import { QuestionsApi } from '../../api/questionsApi';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCDropdown from '../../components/form/LCDropdown';
import LCRadioButton from '../../components/form/LCRadioButton';
import LCSimpleMessage from '../../components/LCMessage';
import { GetCategories, ListQuestionsBankRequest, Tags } from '../../models/questionsApi';
import { Tag } from 'primereact/tag';

export interface EditAnswerDialogAttributes {
  show: () => void;
}
interface EditAnswerDialogProps {
  answerId: number;
  questionContent: string;
  originalContent: string;
  adminContent: string;
  questionTitle?: string;
  questionTags?: Tags[];
  answerEditedCallback: () => void;
}

const EditAnswerDialog = React.forwardRef<EditAnswerDialogAttributes, EditAnswerDialogProps>(
  (
    { questionTags, questionContent, answerId, originalContent, adminContent, answerEditedCallback, questionTitle },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [answerEdited, setAnswerEdited] = useState(false);
    const [questionTitleState, setQuestionTitle] = useState(questionTitle);
    const [questions, setQuestions] = useState<Array<{ id: number; display: string }>>(null);
    const [comment, setComment] = useState(' ');
    const [secLoading, setSecLoading] = useState(true);
    const [categories, setcategories] = useState<GetCategories[]>([]);
    const [category, setCategory] = useState<number>();
    const [subcategory, setSubCategory] = useState([]);
    const [tags, setTags] = useState<Tags[]>(questionTags || []);
    const [tagInput, setTagInput] = useState<string>('');

    useImperativeHandle(ref, () => {
      return {
        show: () => setVisible(true),
      };
    });

    useEffect(() => {
      setAnswerEdited(false);
      setTags(questionTags || []);
    }, [visible]);

    useEffect(() => {
      QuestionsApi.getCategories().then(
        (res) => {
          // console.log(res.data.data);
          setcategories(res.data.data);
          setSecLoading(false);
        },
        () => {
          setSecLoading(false);
        }
      );
      return () => {};
    }, []);

    useEffect(() => {
      categories.filter((v) => {
        if (v.id === category) {
          //data.push(...v.subCategory)
          setSubCategory([...subcategory, ...v.subCategory]);
        }
      });
      getQuestionsList();
      return () => {};
    }, [category]);

    const getQuestionsList = (request?: ListQuestionsBankRequest, setSubmitting?: (submitting: boolean) => void) => {
      const body: ListQuestionsBankRequest = request
        ? request
        : {
            searchParams: {
              term: '',
              from: null,
              to: null,
              types: [],
              categoryId: null,
              tagTerm: [],
              tag: false,
            },
          };

      QuestionsApi.listQuestionsBank(body).then(
        (res) => {
          // console.log(
          //   res.data.data.map((v) => {
          //     return { id: v.id, display: v.title };
          //   })
          // );
          setQuestions(
            res.data.data.map((v) => {
              return { id: v.id, display: v.title };
            })
          );
          //setLoading(false);
        },
        () => {
          //setLoading(false);
        }
      );
    };

    const getSubmitButtonLabel = (newAdminContent: string) => {
      if (adminContent && !newAdminContent) {
        return 'مسح';
      } else {
        return 'تعديل';
      }
    };
    const handleKeyDownChange = async (e: any) => {
      if (e.keyCode === 13) {
        // alert('Enter key pressed' + e.keyCode);
        setComment(comment + '\n');
        // $PLACEHOLDER$
      }
    };
    const handleCommentChange = (e: any) => {
      // console.log(e.target.value);
      setComment(e.target.value);
    };
    return (
      <Dialog
        header={
          <div className="flex w-3/5 gap-2">
            <h1 className="text-3xl font-bold">تعديل الإجابة</h1>
            <div className="h-1 grow bg-black relative self-end bottom-3"></div>
          </div>
        }
        closable={true}
        className="m-5 md:w-1/2 font-main"
        contentClassName="text-lg"
        resizable={false}
        draggable={false}
        dismissableMask={true}
        visible={visible}
        onHide={() => setVisible(false)}>
        {!answerEdited ? (
          <Formik
            initialValues={{ adminContent: adminContent || '', questionTitle: '', type: [], categoryId: category }}
            onSubmit={(values, { setSubmitting }) => {
              // console.log(category);
              AnswerApi.updateAdminContent({
                id: answerId,
                adminContent: values.adminContent,
                questionTitle: values.questionTitle,
                type: values.type,
                categoryId: category,
                tags,
              }).then(
                () => {
                  setSubmitting(false);
                  setAnswerEdited(true);
                  answerEditedCallback();
                },
                () => {
                  setSubmitting(false);
                }
              );
            }}>
            {({ isSubmitting, values, handleChange }) => (
              <Form className="flex flex-col">
                {!secLoading && (
                  <>
                    <p className="text-xl">تصنيف الاستشارة:</p>
                    <div className="lg:flex gap-3 hidden">
                      {categories.map((v, i) => {
                        return (
                          <LCRadioButton
                            checked={category == v.id}
                            key={i}
                            id={`${i}`}
                            name="categoryId"
                            label={v.name}
                            value={`${v.id}`}
                            onChange={() => {
                              setCategory(v.id);
                              setSubCategory([]);
                              values.type.splice(0, values.type.length);
                            }}
                          />
                        );
                      })}
                      <ErrorMessage name="categoryId" className="text-red-600" component="p" />
                    </div>
                    <div className="lg:flex gap-3 hidden">
                      {subcategory.map((v, i) => (
                        <LCCheckbox
                          key={i}
                          value={`${v?.id}`}
                          name="type"
                          id="type"
                          formik={true}
                          label={v?.name}
                          //disabled={!editMode}
                        />
                      ))}
                      <ErrorMessage name="type" className="text-red-600" component="p" />
                    </div>
                    {/* Mobile  */}
                    <div className="block lg:hidden mt-3">
                      <LCDropdown
                        options={categories.map((v, i) => {
                          return { label: v.name, value: v.id };
                        })}
                        value={category}
                        placeholder="اختر تصنيف الاستشارة"
                        onChange={(e) => {
                          setCategory(+e.target.value);
                          setSubCategory([]);
                          values.type.splice(0, values.type.length);
                        }}
                        id="categoryId"
                        name="categoryId"
                        inputClassName="py-0 bg-inputBackground w-full"
                      />
                    </div>
                    <div className="block lg:hidden mt-3">
                      <p className="text-xl mt-2">تصنيف الاستشارة الفرعية:</p>

                      {subcategory.length > 0 && (
                        <MultiSelect
                          options={subcategory.map((v, i) => {
                            return { label: v.name, value: v.id };
                          })}
                          value={values.type}
                          onChange={handleChange}
                          inputId="type"
                          name="type"
                          className="py-0 bg-inputBackground w-full"
                          panelHeaderTemplate={<></>}
                        />
                      )}
                    </div>
                  </>
                )}
                <strong className="mt-4">السؤال</strong>
                <p className="bg-[#f9f9f9] rounded-md p-1">{questionContent}</p>

                <strong className="mt-4">عنوان الاستشارة</strong>
                <Field
                  id="questionTitle"
                  name="questionTitle"
                  // onChange={(e: any) => setQuestionTitle(e.target.value)}
                  // value={questionTitleState}
                  as="textarea"
                  rows="1"
                  className="bg-[#f9f9f9] rounded-md p-1"
                />

                <strong>الإجابة الأصلية:</strong>

                {originalContent?.split('\n').map((line, idx) => (
                  <React.Fragment key={idx}>
                    <p>{line}</p>
                    {idx < originalContent?.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}

                <strong className="mt-4">الإجابة المعدلة:</strong>
                {/* <Field
                  id="adminContent"
                  name="adminContent"
                  as="textarea"
                  rows="5"
                  className="bg-[#f9f9f9] rounded-md p-1"
                /> */}
                <MentionsInput
                  value={comment}
                  name="content"
                  id="content"
                  style={{ backgroundColor: '#f9f9f9', borderRadius: 10, minHeight: 63 }}
                  onKeyDown={(e) => handleKeyDownChange(e)}
                  onChange={(e) => {
                    handleCommentChange(e);
                    values.adminContent = e.target.value;
                  }}>
                  <Mention markup="@__id__" trigger="@" data={questions} style={{ backgroundColor: '#cee4e5' }} />
                </MentionsInput>

                <strong className="mt-4">إضافة وسم</strong>
                <div>
                  <input
                    id="add-tag"
                    name="add-tag"
                    onChange={(e: any) => {
                      const value = e.target.value;
                      setTagInput(value);
                    }}
                    value={tagInput}
                    onSubmit={() => {}}
                    className="bg-[#f9f9f9] rounded-md p-1"
                  />
                  <span
                    className="rounded-md p-1 m-3 border-solid border-inherit border-2 hover:bg-slate-200 hover:cursor-pointer"
                    onClick={() => {
                      if (tagInput.length === 0 || tags.find((tag) => tag.name === tagInput)) {
                        setTagInput('');
                        return;
                      }
                      const tagInputWithoutSpace = tagInput.replace(' ', '_').replace('#', '');

                      setTags((old) => [...old, { name: tagInputWithoutSpace }]);
                      setTagInput('');
                    }}>
                    <i className="pi pi-plus"> أضف وسم</i>
                  </span>
                </div>
                <div>
                  {tags.map((tag) => {
                    return (
                      <span
                        key={tag.name}
                        onClick={() => {
                          setTags((old) => old.filter((item) => item.name !== tag.name));
                        }}>
                        <Tag
                          value={tag.name}
                          className="m-2 hover:cursor-pointer"
                          icon="pi pi-times"
                          severity="success"
                        />
                      </span>
                    );
                  })}
                </div>

                <div className="flex flex-row-reverse gap-2 mt-6">
                  <LCButton
                    label="إلغاء"
                    type="button"
                    buttonStyle="roundedStyle1Outlined"
                    onClick={() => setVisible(false)}
                  />
                  <LCButton
                    label={getSubmitButtonLabel(values.adminContent)}
                    type="submit"
                    buttonStyle="roundedStyle1"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <LCSimpleMessage type="success" label="تم تعديل الإجابة بنجاح" />
            <LCButton
              label="إغلاق"
              type="button"
              buttonStyle="roundedStyle1Outlined"
              className="float-left"
              onClick={() => setVisible(false)}
            />
          </>
        )}
      </Dialog>
    );
  }
);

export default EditAnswerDialog;
