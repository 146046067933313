import { Button, ButtonProps } from 'primereact/button';
import React from 'react';
import './LCButton.css';

interface LCButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps {
  label: string;
  buttonStyle?: 'primary' | 'roundedStyle1' | 'roundedStyle1Outlined' | 'roundedStyle2' | 'noStyle';
  backgroundColorClass?: string;
}

const LCButton = ({
  label,
  buttonStyle = 'primary',
  backgroundColorClass = 'bg-primary',
  className,
  ...props
}: LCButtonProps) => {
  const classNames = {
    primary: `${backgroundColorClass} rounded-md border-none lg:rounded-none lg:rounded-tl-xl lg:rounded-br-xl px-4 lg:px-20 py-1 shadow-none font-main text-lg `,
    roundedStyle1: `${backgroundColorClass} text-white border-none rounded-lg h-6 px-10 shadow-none font-main text-lg`,
    roundedStyle1Outlined:
      'bg-white hover:bg-gray-100 text-primary border border-primary rounded-lg h-6 px-10 shadow-none font-main text-lg',
    roundedStyle2: `${backgroundColorClass} text-white border-none rounded-2xl h-7 px-10 shadow-none font-main text-lg`,
    noStyle: '',
  };

  return <Button label={label} {...props} className={`${classNames[buttonStyle]} ${className}`}></Button>;
};

export default LCButton;
