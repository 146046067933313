import { confirmPopup } from 'primereact/confirmpopup';
import { BsExclamationTriangle } from 'react-icons/bs';
import './lcConfirmPopup.css';

export const lcConfirmPopup = (target: HTMLElement, message: string, accept: () => void) => {
  confirmPopup({
    target: target,
    message: message,
    icon: <BsExclamationTriangle className="text-xl" />,
    acceptClassName: 'bg-primary font-main shadow-none border-none',
    rejectClassName: 'bg-white text-primary font-main shadow-none border-none hover:bg-gray-100',
    accept: accept,
  });
};
