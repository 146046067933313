import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaHashtag } from 'react-icons/fa';
import { QuestionsApi } from '../../api/questionsApi';
import avatar_1 from '../../assets/images/avatar_1.png';
import { QuestionStatuses, Tags } from '../../models/questionsApi';
import RejectQuestionDialog, {
  RejectQuestionDialogAttributes,
} from '../../pages/questionsForAnswer/RejectQuestionDialog';
import { questionStatusMapper } from '../../utility/questionStatusMapper';
import { getFormattedDate } from '../../utility/stringUtility';
import LinkBtn from '../button/LinkBtn';
import LCButton from '../form/LCButton';
import LCCheckbox from '../form/LCCheckbox';
import LCRadioButton from '../form/LCRadioButton';
import ReassignQuestionPopup, { ReassignQuestionPopupAttributes } from '../ReassignQuestionPopup';

interface QuestionForAnswerCardProps {
  id: number;
  title: string;
  date: string;
  content: string;
  status: QuestionStatuses;
  link: string;
  isJudge: boolean;
  questionRejectedCallback: () => void;
  questionReassignedCallback: () => void;
  afterUpdate?: Function;
}

const QuestionForEditHashtagCard = ({
  id,
  title,
  date,
  content,
  status,
  link,
  questionRejectedCallback,
  questionReassignedCallback,
  isJudge,
  afterUpdate,
}: QuestionForAnswerCardProps) => {
  const actionMenu = useRef(null);
  const rejectQuestionDialogRef = useRef<RejectQuestionDialogAttributes>(null);
  const reassignQuestionPopupRef = useRef<ReassignQuestionPopupAttributes>(null);
  const [questionTags, setQuestionsTags] = useState<Tags[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const actionMenuItems: MenuItem[] = [
    {
      label: 'الاعتذار من الإجابة',
      command: () => rejectQuestionDialogRef.current.show(),
    },
    {
      label: 'إعادة إسناد الاستشارة',
      command: (e) => reassignQuestionPopupRef.current.show(e.originalEvent as any),
    },
  ];

  const canTakeAction = (status: QuestionStatuses) => {
    return status === QuestionStatuses.ASSIGNED_TO_CONSULTANT || status === QuestionStatuses.RETURNED_TO_CONSULTANT;
  };

  const shouldShowStatus = (status: QuestionStatuses) => {
    return status === 'ASSIGNED_TO_CONSULTANT' || status === 'NEW' || status === 'RETURNED_TO_CONSULTANT';
  };

  const deleteQuestionTag = (index: number) => {
    const data = questionTags.filter((v, i) => i != index);
    setQuestionsTags(data);
  };

  const isHashtag = (value: string, keyCode: number, code: string): void => {
    const regexExp = /^#[^ !@#$%^&*(),.?":{}|<>]*$/gi;
    let tagWithoutSymb: Array<string>;

    if (keyCode === 32 || code === 'Space')
      value.split(' ').map((v, i) => {
        if (regexExp.test(v)) {
          tagWithoutSymb = v?.split('#');
          const filterTags = questionTags.filter((v) => v?.name == tagWithoutSymb[1]);
          if (filterTags?.length === 0 && v?.trim() !== '#')
            setQuestionsTags([...questionTags, { name: tagWithoutSymb[1] }]);
        }
      });
  };

  return (
    <div className="c-container">
      <div className="c-header-intro">
        <div className="c-imgs">
          <div className="c-user-img">
            <img src={avatar_1} alt="user" width="66px" height="66px" className="w-[66px] h-[66px]" />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <h2 className="c-title-co">{title}</h2>
              {shouldShowStatus(status) && (
                <small className="bg-[#9ee8af52] text-primary font-main rounded-full text-sm text-center px-1 py-1">
                  {questionStatusMapper(status).name}
                </small>
              )}
            </div>
            <span className="c-date">{getFormattedDate(date)}</span>
          </div>
        </div>
        {canTakeAction(status) && (
          <div className="c-menu">
            <button className="c-menu-btn" onClick={(event) => actionMenu.current.toggle(event)}>
              <i className="pi pi-ellipsis-h"></i>
            </button>
            <Menu model={actionMenuItems} ref={actionMenu} popup />
            <RejectQuestionDialog
              ref={rejectQuestionDialogRef}
              id={id}
              isJudge={isJudge}
              questionRejectedCallback={questionRejectedCallback}
            />
            {/** TODO: Create a consultant reassign questions dialog to show a loading indicator */}
            <ReassignQuestionPopup
              ref={reassignQuestionPopupRef}
              id={id}
              type="answer"
              setLoading={() => {}}
              successCallback={questionReassignedCallback}
            />
          </div>
        )}
      </div>
      <div className="c-content">
        <p>{content}</p>
      </div>

      {/*  */}

      <>
        <Formik
          initialValues={{
            tags: '',
          }}
          onSubmit={(values: any, { setSubmitting }: any) => {
            setLoading(true);

            QuestionsApi.updateHashtagForQuestionWithoutHashtags({ tags: questionTags, id }).then((res) => {
              setLoading((prev) => !prev);
              afterUpdate(id);
            });

            // alert(id);
          }}>
          {({ values }) => (
            <Form className="flex flex-col gap-5">
              <div className="grid grid-cols-1-form lg:grid-cols-2-form lg:gap-x-4 gap-y-2">
                <div className="flex items-center gap-2">
                  <p className="text-xl font-bold">وسم الاستشارة </p>
                  <FaHashtag className="hidden lg:inline-block" />
                </div>
                <div className="flex gap-2 col-start-1 items-center lg:col-start-2">
                  <div className="grow">
                    <Field
                      id="tags"
                      name="tags"
                      as="textarea"
                      onKeyUp={(e: any) => {
                        isHashtag(e.target.value, e.keyCode, e.code);
                        values.tags = '';
                      }}
                      className="bg-[#f9f9f9] rounded-xl w-full p-4 font-bold text-[#1d9bf0]"
                      rows="1"
                    />
                    <div className="bg-[#f9f9f9] flex flex-row w-full p-4 font-bold text-white">
                      {questionTags.map((v, i) => (
                        <div className="rounded-full flex  hover:cursor-pointer flex-row justify-between bg-primary opacity-80 p-1 m-1">
                          <div onClick={() => deleteQuestionTag(i)} className="w-3 border-l ml-2">
                            x
                          </div>
                          {v.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <LCButton
                  type="submit"
                  label="حفظ"
                  buttonStyle="primary"
                  className="self-center mt-5 md:px-20"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </>
    </div>
  );
};

export default QuestionForEditHashtagCard;
