import { PaginatorTemplate } from 'primereact/paginator';

export const PAGINATION_LIMIT = 20;

export const paginatorTemplate: PaginatorTemplate = {
  layout: 'CurrentPageReport NextPageLink PageLinks PrevPageLink FirstPageLink',
  CurrentPageReport: (options) => {
    return (
      <p className="text-[#4e4e4e] font-main text-lg block md:inline-block md:float-right">
        إظهار {options.first} إلى {options.last} من أصل {options.totalRecords} عناصر
      </p>
    );
  },
  NextPageLink: (options) => {
    let className = 'bg-[#eeeeee] rounded-lg font-main py-1 px-5 float-left';
    className += options.disabled ? ' text-gray-600' : ' text-primary';

    return (
      <button type="button" className={className} disabled={options.disabled} onClick={options.onClick}>
        التالي
      </button>
    );
  },
  PrevPageLink: (options) => {
    let className = 'bg-[#eeeeee] rounded-lg font-main py-1 px-4 float-left';
    className += options.disabled ? ' text-gray-600' : ' text-primary';

    return (
      <button type="button" className={className} disabled={options.disabled} onClick={options.onClick}>
        السابق
      </button>
    );
  },
  PageLinks: (options) => {
    let className = 'px-3 py-1 font-main border-gray-700 border-y';
    className += options.currentPage === options.page ? ' bg-primary text-white' : ' bg-white text-black';

    if (options.page === options.view.startPage) {
      className += ' border-r';
    } else if (options.page === options.view.endPage) {
      className += ' border-l';
    }

    return (
      <button type="button" className={className} onClick={options.onClick}>
        {options.page + 1}
      </button>
    );
  },
  RowsPerPageDropdown: (options) => {},
  FirstPageLink: (options) => {
    let className = 'bg-[#eeeeee] rounded-lg font-main py-1 px-5 float-left mx-2';
    className += options.disabled ? ' text-gray-600' : ' text-primary';

    return (
      <button type="button" className={className} disabled={options.disabled} onClick={options.onClick}>
        الصفحة الأولى
      </button>
    );
  },
  LastPageLink: (options) => {},
  JumpToPageInput: (options) => {},
};
