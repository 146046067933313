import axios from 'axios';
import { ToastManager } from '../components/toast/toastManager';
//import { USER_LOCAL_STORAGE_KEY } from '../models/userContext';
import { UserContextProxy } from '../models/userContextProxy';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  withCredentials: true,
});

// axiosInstance.interceptors.request.use((config) => {
//   const user = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY));
//   if (user) config.headers['token'] = user.authToken;
//   return config;
// });

const refresh_token = () => {
  return axiosInstance.get('/auth/refresh').catch((err) => console.log(err));
};

let refreshing_token: any;
axiosInstance.interceptors.response.use(
  (response) => {
    const message = response.data.message ? `Success: ${response.data.message}` : 'Success';
    return response;
  },
  async (error) => {
    const status = error.response?.status;
    const summary = status === 403 ? 'لا تمتلك الصلاحيات المطلوبة' : 'خطأ';
    const originalConfig = error.config;
    // Handle refresh request errors
    if (status === 401) UserContextProxy.resetUser();
    // if (originalConfig.url === "/auth/refresh" && status === 401) {
    //   UserContextProxy.resetUser()
    // }
    // // handle only 401 errors
    // if (originalConfig.url != "/auth/login" && error.response.status === 401 && !originalConfig._retry) {
    //   originalConfig._retry = true;

    //   // Access Token was expires
    //   try {
    //     refreshing_token = refreshing_token ? refreshing_token : refresh_token();
    //     let res = await refreshing_token;
    //     refreshing_token = null;
    //     if (res.status === 401) {
    //       UserContextProxy.resetUser()
    //     }
    //     return axiosInstance(originalConfig);
    //   } catch (_error) {
    //     return Promise.reject(_error);
    //   }

    // }
    ToastManager.showError(summary, error.response?.data?.message);
    return Promise.reject(error);
  }
);

export { axiosInstance as Axios };
