import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import React, { useContext, useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import * as Yup from 'yup';
import { QuestionsApi } from '../../api/questionsApi';
import icon_chat from '../../assets/icons/icon_chat.svg';
import create_question_image from '../../assets/images/create_question_image.jpeg';
import LCButton from '../../components/form/LCButton';
import LCCheckbox from '../../components/form/LCCheckbox';
import LCLinkButton from '../../components/form/LCLinkButton';
import LCSimpleMessage from '../../components/LCMessage';
import QuestionsSlider from '../../components/QuestionsSlider';
import TermsAndConditionsDialog, {
  TermsAndConditionsDialogAttributes,
} from '../../components/TermsAndConditionsDialog';
import { UserContext } from '../../contexts/UserContext';
import { UserContextProxy } from '../../models/userContextProxy';
import './createQuestion.css';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const CreateQuestion = () => {
  const { isLoggedIn } = useContext(UserContext);
  const [questionAdded, setQuestionAdded] = useState(false);
  const termsRef = useRef<TermsAndConditionsDialogAttributes>(null);
  const navigate = useNavigate();
  const [capVal, setCapVal] = React.useState(null);

  const QUESTION_WORD_LIMIT = 60;
  const QUESTION_WORD_WARNING_LIMIT = 50;

  const getNumberOfWords = (text: string) => {
    if (!text) return;
    return text?.split(' ').filter((word) => word !== '').length;
  };

  const getValidationSchema = () => {
    const object: any = {
      // title: Yup.string().required('إلزامي'),
      content: Yup.string()
        .required('إلزامي')
        .test(
          'within-word-limit',
          `يجب ألا يتجاوز السؤال ${QUESTION_WORD_LIMIT} كلمة`,
          (value) => getNumberOfWords(value) <= QUESTION_WORD_LIMIT
        )
        .max(350, 'يجب أن لا يتجاوز السؤال ثلاثمائة و خمسون حرفا '),
    };

    if (!isLoggedIn()) {
      object['email'] = Yup.string().email('البريد الإلكتروني غير صحيح').required('إلزامي');
      object['agreedToTerms'] = Yup.boolean().isTrue('إلزامي');
    }

    return Yup.object(object);
  };

  const displayTermsAndConditions = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    termsRef.current.show();
  };

  return (
    <div className="grow">
      <div className="c-q ">
        <div className="c-q-content">
          <div className="flex items-center gap-3">
            <img src={icon_chat} alt="Icon" className="h-10" />
            <h1 className="text-4xl text-white lg:text-black">اسأل المستشار اللغوي</h1>
          </div>

          <Formik
            initialValues={{
              title: '',
              content: '',
              email: !UserContextProxy.email ? '' : UserContextProxy.email,
              agreedToTerms: false,
            }}
            validationSchema={getValidationSchema()}
            onSubmit={(values, { setSubmitting }) => {
              ReactGA.event({
                category: 'createNewConsultation',
                action: 'send',
                label: 'newConsultation',
              });
              ReactPixel.track('newConsultation', 'createNewConsultation');
              if (values.email) {
                values.email = values.email.toLowerCase();
              }
              QuestionsApi.createQuestion(values).then(
                () => {
                  setSubmitting(false);
                  setQuestionAdded(true);
                  navigate('/new-question/success');
                },
                () => {
                  setSubmitting(false);
                }
              );
            }}>
            {({ isSubmitting, values }) =>
              !questionAdded ? (
                <>
                  <p className="c-q-form-p">
                    يمكنك إضافة استشارة لغوية وفق معايير وشروط معينة، يمكنك الضغط على أيقونة الاستعلام بجانب الحقل
                    لتفاصيل الاستشارة اللغوية
                  </p>

                  <Form className="c-q-form">
                    {/* <div className="c-q-form-field">
                        <label htmlFor="title" className="c-q-form-label">
                          عنوان الاستشارة اللغوية
                        </label>
                        <Field id="title" name="title" type="text" className="c-q-form-input " />
                        <ErrorMessage name="title" className="text-red-600" component="span" />
                      </div> */}

                    <div className="flex flex-col w-full gap-1">
                      <label htmlFor="content" className="text-xl">
                        <span className="text-white lg:text-black">تفاصيل الاستشارة اللغوية</span>
                        <AiFillInfoCircle id="question-rules-icon" className="inline-block mr-2" />
                        <Tooltip target="#question-rules-icon" position="left" className="!fill-white lg:!fill-black ">
                          <div className="c-q-tooltip ">
                            <p className="c-q-title-tip font-main">
                              <i className="pi pi-info c-q-icon lg:hidden"></i>
                              يرجى الالتزام بمعايير الاستشارة
                            </p>
                            <ul className="p-4 text-black list-disc lg:text-white lg:p-3 font-main ">
                              <li>السؤال عن مسألة لغوية أو كتابية</li>
                              <li>السؤال محدد</li>
                              <li>السؤال عن مسألة واحدة</li>
                              <li>ألا يُربط السؤال بأشخاص أو كيانات</li>
                              <li>ألا يتجاوز السؤال {QUESTION_WORD_LIMIT} كلمة</li>
                            </ul>
                          </div>
                        </Tooltip>
                      </label>
                      <div className="c-q-form-textarea-container">
                        <label htmlFor="content" className="inline-block w-full">
                          السلام عليكم ورحمة الله وبركاته <br /> سؤالي هو:
                        </label>
                        <Field id="content" name="content" as="textarea" className="c-q-form-textarea" rows={7} />
                        <label htmlFor="content" className="inline-block w-full">
                          وشكرًا
                        </label>
                      </div>
                      {getNumberOfWords(values.content) >= QUESTION_WORD_WARNING_LIMIT && (
                        <p>طول السؤال {`${getNumberOfWords(values.content)} / ${QUESTION_WORD_LIMIT}`} كلمة</p>
                      )}
                      <ErrorMessage name="content" className="text-red-600" component="span" />
                    </div>

                    {!isLoggedIn() && (
                      <>
                        <div className="flex flex-col w-full gap-1">
                          <label htmlFor="email" className="c-q-form-label">
                            البريد الإلكتروني
                          </label>
                          <Field id="email" name="email" type="email" className="c-q-form-input" />
                          <ErrorMessage name="email" className="text-red-600" component="span" />
                        </div>

                        <div className="flex flex-col w-full">
                          <LCCheckbox
                            id="agreedToTerms"
                            name="agreedToTerms"
                            color="black"
                            formik={true}
                            label={
                              <span>
                                <span className="text-white md:text-black">أوافق على &nbsp;</span>
                                <span
                                  className="text-white md:text-primary cursor-pointer underline md:no-underline md:hover:underline"
                                  onClick={displayTermsAndConditions}>
                                  الشروط والأحكام
                                </span>
                              </span>
                            }
                          />
                          <TermsAndConditionsDialog ref={termsRef} />
                        </div>
                      </>
                    )}
                    <ReCAPTCHA
                      sitekey="6LfyvAkqAAAAAE_AcTYiG1FBQVpf6a1qKyx7fZ9H"
                      onChange={(val: any) => {
                        setCapVal(val);
                      }}
                    />
                    <LCButton
                      label="إرسال"
                      type="submit"
                      buttonStyle="roundedStyle1"
                      className="w-full py-4 lg:w-1/2"
                      disabled={capVal === null || isSubmitting}
                      loading={isSubmitting}
                    />
                  </Form>
                </>
              ) : (
                <>
                  <LCSimpleMessage
                    label="لقد أرسلت المشورة بنجاح وسترسل الإجابة إلى بريدك الإلكتروني خلال ٢٤  ساعة"
                    type="success"
                    className="mt-5 text-white md:text-black"
                  />
                  {isLoggedIn() ? (
                    <LCLinkButton label="قائمة استشاراتي" to="/my-questions" className="inline-block mt-5" />
                  ) : (
                    <LCLinkButton label="عودة للصفحة الرئيسة" to="/" className="inline-block mt-5" />
                  )}
                </>
              )
            }
          </Formik>
        </div>

        <img src={create_question_image} alt="create-background" className="c-q-bg-img" />
      </div>

      <div className="c-q-container">
        <div className="c-q-title-card">
          <img src={icon_chat} alt="Icon" className="h-10" />
          <h1 className="text-4xl">خزانة الاستشارات</h1>
        </div>

        <div className="c-q-card">
          <QuestionsSlider />
        </div>
      </div>
    </div>
  );
};

export default CreateQuestion;
