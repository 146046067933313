import { ErrorMessage, Field } from 'formik';
import React from 'react';

// TODO: Add proper type
interface LCInputTextRoundedProps {
  id?: string;
  name?: string;
  type?: string;
  containerClassName?: string;
  inputClassName?: string;
}

const LCInputTextRounded = ({ containerClassName, inputClassName, ...props }: LCInputTextRoundedProps) => {
  return (
    <div className={containerClassName}>
      <Field {...props} className={`border-2 border-inputBackground rounded-lg px-2 ${inputClassName}`} />
      <ErrorMessage name={props.name} className="text-red-600 inline-block" component="span" />
    </div>
  );
};

export default LCInputTextRounded;
