import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import * as Yup from 'yup';
import { QuestionsApi } from '../../api/questionsApi';
import LCButton from '../../components/form/LCButton';
import './questionsForReview.css';

interface ReturnQuestionDialogProps {
  id: number;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  questionReturnedCallback: () => void;
}

const ReturnQuestionDialog = ({ id, visible, setVisible, questionReturnedCallback }: ReturnQuestionDialogProps) => {
  return (
    <Dialog
      header={
        <div className="flex w-3/5 gap-2">
          <h1 className="text-3xl font-bold">مراجعة الإجابة</h1>
          <div className="h-1 grow bg-black relative self-end bottom-3"></div>
        </div>
      }
      closable={true}
      className="dialog"
      contentClassName="text-lg"
      resizable={false}
      draggable={false}
      dismissableMask={true}
      visible={visible}
      onHide={() => setVisible(false)}>
      <Formik
        initialValues={{
          comments: '',
        }}
        validationSchema={Yup.object({
          comments: Yup.string().required('إلزامي'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // QuestionsApi.returnQuestionToConsultant({ id, comments: values.comments }).then(
          //   () => {
          //     setSubmitting(false);
          //     questionReturnedCallback();
          //     setVisible(false);
          //   },
          //   () => {
          //     setSubmitting(false);
          //   }
          // );
        }}>
        {({ isSubmitting }) => (
          <Form className="flex flex-col gap-1">
            <div className="mt-4">
              <Field
                id="comments"
                name="comments"
                as="textarea"
                className="bg-[#f9f9f9] rounded-xl w-full p-4"
                placeholder="ملاحظات"
                rows="5"
              />
              <ErrorMessage name="comments" className="text-red-600" component="p" />
            </div>

            <LCButton
              label="إرسال"
              type="submit"
              buttonStyle="roundedStyle1"
              className="self-start mt-6"
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ReturnQuestionDialog;
