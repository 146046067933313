import avatar_1 from '../../assets/images/avatar_1.png';
import { QuestionStatuses } from '../../models/questionsApi';
import { questionStatusMapper } from '../../utility/questionStatusMapper';
import { getFormattedDate } from '../../utility/stringUtility';
import LinkBtn from '../button/LinkBtn';
import './consultantsHome.css';
interface QuestionForReviewCardProps {
  title: string;
  date: string;
  content: string;
  status: QuestionStatuses;
  link: string;
}

const QuestionForReviewCard = ({ title, date, content, link, status }: QuestionForReviewCardProps) => {
  const shouldShowStatus = (status: QuestionStatuses) => {
    return status === 'ASSIGNED_TO_JUDGE';
  };

  return (
    <div className="cc-container">
      <div className="cc-header">
        <div className="cc-container-inner">
          <div className="cc-inner">
            <img src={avatar_1} alt="user" className="w-[66px] h-[66px]" />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2">
              <h2 className="cc-title">{title}</h2>
              {shouldShowStatus(status) && (
                <small className="bg-[#9ee8af52] text-primary font-main rounded-full text-sm text-center px-1 py-1">
                  {questionStatusMapper(status).name}
                </small>
              )}
            </div>
            <span className="cc-date">{getFormattedDate(date)}</span>
          </div>
        </div>
      </div>
      <div className="cc-body">
        <p>{content}</p>
      </div>
      <div className="cc-btn">
        <LinkBtn href={link}>مراجعة الاستشارة</LinkBtn>
      </div>
    </div>
  );
};

export default QuestionForReviewCard;
