import { Link } from 'react-router-dom';
import './Button.css';

interface LinkBtnProps {
  styleBtn?: string;
  href: string;
  children?: React.ReactNode;
}

const LinkBtn = ({ styleBtn, href, children }: LinkBtnProps) => {
  const linkBtnClasses = `btn ${styleBtn}`;
  return (
    <Link to={href} className={linkBtnClasses}>
      {children}
    </Link>
  );
};

export default LinkBtn;
