import { TabPanel, TabView } from 'primereact/tabview';
import { useContext, useState } from 'react';
import icon_book from '../../../assets/icons/icon_book.svg';
import ConsultantInfo from '../../../components/consultantsHome/ConsultantInfo';
import { UserContext } from '../../../contexts/UserContext';
import { Permissions } from '../../../models/user';
import './ConsultantsHome.css';
import QuestionsForAnswerList from './QuestionsForAnswerList';
import QuestionsForEditHashtag from './QuestionsForEditHashtag';
import QuestionsForReviewList from './QuestionsForReviewList';
import QuestionsCounters from './QuetionsCounters';
import { savedActiveIndex, setSavedActiveIndex } from './savedActiveIndex';

const ConsultantsHome = () => {
  const userContext = useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState(savedActiveIndex);

  return (
    <div className="grow container mx-auto">
      <div className="cl-wrapper">
        <ConsultantInfo summaryDisplayed={activeIndex === 0 ? 'consultant' : 'reviewer'} />
        <div className="cl-inner">
          <div className="md:flex flex-row justify-between mb-4">
            <div className="hidden md:flex flex-row">
              <div className="w-[30px] h-[30px] mx-2">
                <img src={icon_book} alt="Icon" />
              </div>
              <h2 className="font-main text-4xl">قائمة الاستشارات</h2>
            </div>
            <QuestionsCounters isJudg={userContext.hasPermission(Permissions.JUDGE_QUESTIONS)} />
          </div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
              setSavedActiveIndex(e.index);
            }}>
            <TabPanel header="استشارات مسندة">
              <QuestionsForAnswerList />
            </TabPanel>
            {userContext.hasPermission(Permissions.JUDGE_QUESTIONS) && (
              <TabPanel header="استشارات تحكيم ">
                <QuestionsForReviewList />
              </TabPanel>
            )}
            <TabPanel header="استشارات لا تحتوي على وسوم">
              <QuestionsForEditHashtag />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default ConsultantsHome;
