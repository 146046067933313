import { BackendResponse } from '../models/backendResponse';
import { ListPermissionsResponseItem } from '../models/permissionsApi';
import { Axios } from './axios';

const baseUrl = '/permissions';

const listPermissions = () => {
  return Axios.get<BackendResponse<ListPermissionsResponseItem[]>>(`${baseUrl}`);
};

export const PermissionsApi = {
  listPermissions,
};
