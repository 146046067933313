import { BackendResponse } from '../models/backendResponse';
import { ListUserTypesWithPermissionsResponseItem } from '../models/userTypesApi';
import { Axios } from './axios';

const baseUrl = '/user_types';

const listUserTypesWithPermissions = () => {
  return Axios.get<BackendResponse<ListUserTypesWithPermissionsResponseItem[]>>(`${baseUrl}/permissions`);
};

export const UserTypesApi = {
  listUserTypesWithPermissions,
};
