import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../api/questionsApi';
import icon_folders from '../../assets/icons/icon_folders.svg';
import { GetQuestionsSummaryByConsultantRequest } from '../../models/questionsApi';
import './dashboard.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateRangeI } from '../../pages/Reports';

interface BoxConsultantsTableProps {
  dateRange: DateRangeI;
  setTableLoading: (isLoading: boolean) => void;
}

const BoxConsultantsTable: React.FC<BoxConsultantsTableProps> = ({ dateRange, setTableLoading }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData({ searchParams: dateRange });
  }, [dateRange]);

  useEffect(() => {
    setTableLoading(loading);
  }, [loading, setTableLoading]);

  const loadData = (
    request?: GetQuestionsSummaryByConsultantRequest,
    setSubmitting?: (submitting: boolean) => void
  ) => {
    const body: GetQuestionsSummaryByConsultantRequest = request
      ? request
      : {
          searchParams: {
            from: dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
            to: dayjs(new Date()).format('YYYY-MM-DD'),
          },
        };

    setLoading(true);
    QuestionsApi.getQuestionsSummaryByConsultant(body).then(
      (res) => {
        setData(res.data.data);
        setLoading(false);
        setSubmitting && setSubmitting(false);
      },
      () => {
        setLoading(false);
        setSubmitting && setSubmitting(false);
      }
    );
  };

  return (
    <Card className="d-c">
      <div className="flex items-center gap-2">
        <div>
          <img src={icon_folders} alt="icon" className="h-8" />
        </div>
        <h2 className="d-c-title">احصائيات الاستشارات حسب المستشارين</h2>
      </div>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <DataTable value={data} tableStyle={{ minWidth: '' }}>
          <Column field="total" header="عدد الاستشارات" sortable style={{ width: '7%' }}></Column>
          <Column field="status" header="الحالة" sortable style={{ width: '25%' }}></Column>
          <Column
            field="firstName"
            header=" إسم المستشارالاول"
            sortable
            style={{ width: '25%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}></Column>
          <Column
            field="lastName"
            header=" إسم المستشار الاخير"
            style={{ width: '25%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      )}
    </Card>
  );
};

export default BoxConsultantsTable;
