import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { QuestionsApi } from '../../api/questionsApi';
import { GetQuestionsCategoryCountItem, QuestionCategoryCountRequest } from '../../models/questionsApi';
import './dashboard.css';
import ChartTable from '../ui/ChartRow/ChartTable';
import { DialogDateRange } from './DateRangeDialog';
import { DateRangeI } from '../../pages/Reports';

const BoxQuestionCategoryChart = (props: any) => {
  const categoryId = props.id;
  const categoryTitle = props.title;
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const getDateStrings = (from: Date, to: Date): DateRangeI => {
    return {
      from: from ? dayjs(from).format('YYYY-MM-DD') : dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
      to: to ? dayjs(to).format('YYYY-MM-DD') : dayjs(new Date()).format('YYYY-MM-DD'),
    };
  };

  const loadData = (request?: QuestionCategoryCountRequest, setSubmitting?: (submitting: boolean) => void) => {
    const body: QuestionCategoryCountRequest = request
      ? request
      : {
          mainCatId: categoryId,
          searchParams: {
            from: dayjs(new Date('1970-01-01')).format('YYYY-MM-DD'),
            to: dayjs(new Date()).format('YYYY-MM-DD'),
          },
        };

    setLoading(true);

    QuestionsApi.getQuestionsCategoryCount(body).then(
      (res) => {
        setDataFromResponse(res.data);
        setLoading(false);
        setSubmitting && setSubmitting(false);
      },
      () => {
        setLoading(false);
        setSubmitting && setSubmitting(false);
      }
    );
  };

  const setDataFromResponse = (response: GetQuestionsCategoryCountItem[]) => {
    let labels: string[] = [];
    let data: number[] = [];
    let total = 0;
    response?.map((item) => {
      labels.push(item.subCategory.name);
      total = Number.parseInt(total + item.questionCount);
      data.push(Number.parseInt(item.questionCount));
    });
    let questionTotalCount = 0;
    response.map((item) => (questionTotalCount = questionTotalCount + parseInt(item.questionCount)));
    setNewData(
      response.map((item) => ({
        title: item.subCategory.name,
        percent: ((parseInt(item.questionCount) / questionTotalCount) * 100).toFixed(2),
        tooltip: item.questionCount,
      }))
    );
  };

  const handleDateChange = (dateRanges: DialogDateRange) => {
    loadData({
      mainCatId: categoryId,
      searchParams: getDateStrings(dateRanges.from, dateRanges.to),
    });
  };
  return (
    <Card className="d-c h-[400px]">
      <ChartTable isLoading={loading} data={newData} headerTitle={categoryTitle} onChangeDate={handleDateChange} />
    </Card>
  );
};

export default BoxQuestionCategoryChart;
